import { Mission } from './Mission';
import { Players } from './Players';
import { Goals } from './Goals';
import { Goals2 } from './Goals2';
import { LetsPlay } from './LetsPlay';

export const Instruction = (props) => {
  const {showComponent,hideComponent,showPlayers,setShowPlayers,showMission,setShowMission,showGoals,setShowGoals,showGoals2,setShowGoals2,showLetsPlay,setShowLetsPlay,setShowConfiguration,setShowInstruction,setShowPreGame,setShowLanding,setShowStrategy} = props;
  setShowLanding(false);
  //setShowInstruction(false);
  setShowConfiguration(false); 
  setShowPreGame(false);
  setShowStrategy(false);
  if(showMission === false && showPlayers === false && showGoals === false && showGoals2 === false && showLetsPlay === false){
    setShowMission(true);
  }
  return (
    <>      
      {showMission && <Mission showComponent={showComponent} hideComponent={hideComponent} showPlayers={showPlayers} setShowPlayers={setShowPlayers} showMission={showMission} setShowMission={setShowMission} showGoals={showGoals} setShowGoals={setShowGoals} setShowLanding={setShowLanding} />}
      {showPlayers && <Players showComponent={showComponent} hideComponent={hideComponent} showPlayers={showPlayers} setShowPlayers={setShowPlayers} showMission={showMission} setShowMission={setShowMission} showGoals={showGoals} setShowGoals={setShowGoals} setShowLanding={setShowLanding} />}
      {showGoals && <Goals showComponent={showComponent} hideComponent={hideComponent} showMission={showMission} setShowMission={setShowMission} showGoals={showGoals} setShowGoals={setShowGoals} showGoals2={showGoals2} setShowGoals2={setShowGoals2} setShowConfiguration={setShowConfiguration} setShowInstruction={setShowInstruction} />}
      {showGoals2 && <Goals2 showComponent={showComponent} hideComponent={hideComponent} showMission={showMission} setShowMission={setShowMission} showGoals={showGoals} setShowGoals={setShowGoals} showGoals2={showGoals2} setShowGoals2={setShowGoals2} showLetsPlay={showLetsPlay} setShowLetsPlay={setShowLetsPlay} setShowConfiguration={setShowConfiguration} setShowInstruction={setShowInstruction} />}
      {showLetsPlay && <LetsPlay showComponent={showComponent} hideComponent={hideComponent} showMission={showMission} setShowMission={setShowMission} showGoals2={showGoals2} setShowGoals2={setShowGoals2} showLetsPlay={showLetsPlay} setShowLetsPlay={setShowLetsPlay} setShowConfiguration={setShowConfiguration} setShowInstruction={setShowInstruction} />}
    </>
  );
}
