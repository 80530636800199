import { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const FinalResults = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    setRound,
    selectedLevel,
    setSelectedLevel,
    selectedCountry,
    setSelectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,    
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    disaster,
    disasters,
    setDisasters,
    showDisasterResult,
    setShowDisasterResult,
    SetBacks,
    set_backs,
    setSet_backs,
    showSetBackSelection,
    setShowSetBackSelection,
    showFinalScores,
    setShowFinalScores,
    resetCountry,
    setResetCountry,
    resetCrop,
    setResetCrop
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social+benefits['Policy Maker'].climate_adaptation-set_backs['Policy Maker'].cost;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social+benefits['Community Leader'].climate_adaptation-set_backs['Community Leader'].cost;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social+benefits['Farmer'].climate_adaptation-set_backs['Farmer'].cost;

  const processReset = (reset)=>{
    //Set Budgets
    setPolicyMakerStrategy({budget: 50});
    setCommunityLeaderStrategy({budget: 30});
    setFarmerStrategy({budget: 20});
    //End
    if(!reset)
      setRound(round+1);      
    else {
      setRound(1);
      setResetCountry(false);
      setResetCrop(false);
      setSelectedCountry('');
      setSelectedCrop({ wheat: false, coffee: false, sugarcane: false, rice: false, maize: false });
      setPolicyMakerStrategy({budget: 50});
      setCommunityLeaderStrategy({budget: 30});
      setFarmerStrategy({budget: 20});
      setTokens({"Policy Maker": {1: null, 2: null, 3: null}, "Community Leader": {1: null, 2: null, 3: null}, Farmer: {1: null, 2: null, 3: null}});
    }
    setBenefits({coins: 0, "Policy Maker": {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}, "Community Leader": {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}, Farmer: {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}});
    setSet_backs({});
    setScores({"Policy Maker": {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}, "Community Leader": {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}, Farmer: {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}});
    crops.current = {display:'',cropnames:[],totalscore: 0,pass: false};
    disaster.current = {};    
    processTransition('FinalResult','Strategy','Configuration');
  }

  let threshold = {upper: 80,lower: 45};
  let coinThreshold = {upper: 30,lower: 20}
  let overall_score = Math.ceil((scores['Policy Maker'].climate_adaptation_score+scores['Community Leader'].climate_adaptation_score+scores['Farmer'].climate_adaptation_score)/3);
  let SDG2_score = Math.ceil((((scores['Policy Maker'].social_score+scores['Community Leader'].social_score+scores['Farmer'].social_score)/3)+((scores['Policy Maker'].economic_score+scores['Community Leader'].economic_score+scores['Farmer'].economic_score)/3))/2);
  let SDG13_score = Math.ceil((scores['Policy Maker'].environmental_score+scores['Community Leader'].environmental_score+scores['Farmer'].environmental_score)/3);
  let overall = calculateScore(overall_score,threshold);
  let SDG2 = calculateScore(SDG2_score,threshold);
  let SDG13 = calculateScore(SDG13_score,threshold);
  let visuals = {"Policy Maker": returnVisuals(calculateScore(policyMakerCoins,coinThreshold)),
                 "Community Leader": returnVisuals(calculateScore(communityLeaderCoins,coinThreshold)),
                 "Farmer": returnVisuals(calculateScore(farmerCoins,coinThreshold)),
                 "Overall": returnVisuals(overall),
                 "SDG2": returnVisuals(SDG2),
                 "SDG13": returnVisuals(SDG13)};

  const [PMSupplement, setPMSupplement] = useState(null);  
  const [CLSupplement, setCLSupplement] = useState(null);  
  const [FSupplement, setFSupplement] = useState(null);  
  const defaultValues = {
      PMSupplement: PMSupplement,
      CLSupplement: CLSupplement,
      FSupplement: FSupplement,
      resetCountry: resetCountry,
      resetCrop: resetCrop      
  };
  const {
      control,
      formState: { errors },
      handleSubmit,
      getValues,
      setValue,
      reset
  } = useForm({ defaultValues });
  const onSubmit = (data: any) => {
      let penalty = 10;
      if(data.resetCountry){
        setSelectedCountry('');
        policyMakerCoins = policyMakerCoins-penalty;
        communityLeaderCoins = communityLeaderCoins-penalty;
        farmerCoins= farmerCoins-penalty;
        setResetCountry(true);
      }
      else
        setResetCountry(false);
      if(data.resetCrop){
        setSelectedCrop({ wheat: false, coffee: false, sugarcane: false, rice: false, maize: false });
        policyMakerCoins = policyMakerCoins-penalty;
        communityLeaderCoins = communityLeaderCoins-penalty;
        farmerCoins= farmerCoins-penalty;
        setResetCrop(true);
      }
      else
        setResetCrop(false);      
      setPolicyMakerStrategy({budget: policyMakerCoins+parseInt(data.PMSupplement)});
      setCommunityLeaderStrategy({budget: communityLeaderCoins+parseInt(data.CLSupplement)});
      setFarmerStrategy({budget: farmerCoins+parseInt(data.FSupplement)});      
      reset();
      processReset(false);
  };
  const getFormErrorMessage = (name:any) => {
      return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  let displayCropEffect = "";  
  Object.entries(crops.current.cropYield).forEach((crop)=>{    
    //displayCropEffect = displayCropEffect+disaster.current.mitigated_loss[crop[0]].toLocaleString()+" out of "+disaster.current.crop_production[crop[0]].toLocaleString()+" tonnes of "+crop[0]+" lost!<br/>";
    displayCropEffect = displayCropEffect+Math.ceil(((disaster.current.mitigated_loss[crop[0]]/disaster.current.crop_production[crop[0]])*100)).toLocaleString()+"% of "+crop[0]+" lost!<br/>";
  });

  const minimumSupplement = useRef<number>(0);
  const [disableNewRound,setDisableNewRound] = useState<boolean>(false);
  const [policyMakerSupplement,setPolicyMakerSupplement] = useState<number>(0);
  const [communityLeaderSupplement,setCommunityLeaderSupplement] = useState<number>(0);
  const [farmerSupplement,setFarmerSupplement] = useState<number>(0);

  //Defined Supplementary budget
  /*const calculateBalance = (target)=>{
    let player = target.substring(0,target.length-10);
    let Player = "";
    if(player === "PM")
      Player = "policyMaker";
    else if(player === "CL")
      Player = "communityLeader";
    else if(player === "F")
      Player = "farmer";

    let balance = eval(Player+'Coins');
    let newBudget = balance+parseInt((document.getElementById(target) as HTMLInputElement).value);
    if(newBudget < minimumSupplement.current){
      alert("Insufficient Supplementary Budget.  A minimum of "+minimumSupplement.current+" coins is required!");
      setValue(target,"");
      eval('set'+target+'(null)');
    }
    else{
      whichChange("general",null);
    }
  }

  const recalculateBalance = (which,checked,other,otherChecked)=>{    
    let PMBudget = policyMakerCoins+(parseInt((document.getElementById('PMSupplement') as HTMLInputElement).value) || 0);
    let CLBudget = communityLeaderCoins+(parseInt((document.getElementById('CLSupplement') as HTMLInputElement).value) || 0);
    let FBudget = farmerCoins+(parseInt((document.getElementById('FSupplement') as HTMLInputElement).value) || 0);

    if(checked){
      PMBudget = PMBudget-10;
      CLBudget = CLBudget-10;
      FBudget = FBudget-10;
    }    
    if(otherChecked){
      PMBudget = PMBudget-10;
      CLBudget = CLBudget-10;
      FBudget = FBudget-10;
    }

    let disable = false;
    PMBudget = PMBudget-20;    
    setPolicyMakerSupplement(PMBudget);    
    if(PMBudget < 0)      
      disable = true;
    CLBudget = CLBudget-20;
    setCommunityLeaderSupplement(CLBudget);
    if(CLBudget < 0)      
      disable = true;
    FBudget = FBudget-20;
    setFarmerSupplement(FBudget);
    if(FBudget < 0)
      disable = true;

    setDisableNewRound(disable);
  }
  const whichChange = (which,checked)=>{
    let other = "";
    if(which === "resetCountry")
      other = "resetCrop";    
    else if(which === "resetCrop")
      other = "resetCountry";
    else{
      which = "resetCountry";
      other = "resetCrop";
    }

    if(typeof(checked) !== "boolean")
      checked = (document.getElementById(which) as HTMLInputElement).checked;    
    else{
      if(checked)
        minimumSupplement.current = minimumSupplement.current+10;
      else
        minimumSupplement.current = minimumSupplement.current-10;
    }
    let otherChecked = (document.getElementById(other) as HTMLInputElement).checked;
    recalculateBalance(which,checked,other,otherChecked);
  }*/
  //End

  //Default budgets, no supplementary budget
  let PMBudget = 50;
  let CLBudget = 30;
  let FBudget = 20;
  const calculateBalance = (target)=>{

  }

  const whichChange = (which,checked)=>{
    let other = "";
    if(which === "resetCountry")
      other = "resetCrop";    
    else if(which === "resetCrop")
      other = "resetCountry";
    else{
      which = "resetCountry";
      other = "resetCrop";
    }

    if(typeof(checked) !== "boolean")
      checked = (document.getElementById(which) as HTMLInputElement).checked;    
    let otherChecked = (document.getElementById(other) as HTMLInputElement).checked;
  }
  //End

  const [finalRound,setFinalRound] = useState(false);
  let nextRound = round+1;

  useEffect(()=>{
    if(nextRound > 3)
      setFinalRound(true);
  },[nextRound]);

  var tempPoints = useRef(points);
  let policyMakerCoinPoints = 0;
  Math.floor(policyMakerCoins/20) > 0 ? policyMakerCoinPoints = Math.floor(policyMakerCoins/20): policyMakerCoinPoints = 0;
  if(tokens['Policy Maker'][round] == "Good")
    policyMakerCoinPoints = policyMakerCoinPoints + 10;
  else if(tokens['Policy Maker'][round] == "Fair")
    policyMakerCoinPoints = policyMakerCoinPoints + 5;
  else if(tokens['Policy Maker'][round] == "Poor")
    policyMakerCoinPoints = policyMakerCoinPoints + 1;
  let communityLeaderCoinPoints = 0;
  Math.floor(communityLeaderCoins/20) > 0 ? communityLeaderCoinPoints = Math.floor(communityLeaderCoins/20): communityLeaderCoinPoints = 0;
  if(tokens['Community Leader'][round] == "Good")
    communityLeaderCoinPoints = communityLeaderCoinPoints + 10;
  else if(tokens['Community Leader'][round] == "Fair")
    communityLeaderCoinPoints = communityLeaderCoinPoints + 5;
  else if(tokens['Community Leader'][round] == "Poor")
    communityLeaderCoinPoints = communityLeaderCoinPoints + 1;
  let farmerCoinPoints = 0;
  Math.floor(farmerCoins/20) > 0 ? farmerCoinPoints = Math.floor(farmerCoins/20): farmerCoinPoints = 0;
  if(tokens['Farmer'][round] == "Good")
    farmerCoinPoints = farmerCoinPoints + 10;
  else if(tokens['Farmer'][round] == "Fair")
    farmerCoinPoints = farmerCoinPoints + 5;
  else if(tokens['Farmer'][round] == "Poor")
    farmerCoinPoints = farmerCoinPoints + 1;
  tempPoints.current["Policy Maker"] = {...tempPoints.current["Policy Maker"], [round]: policyMakerCoinPoints};
  tempPoints.current["Community Leader"] = {...tempPoints.current["Community Leader"], [round]: communityLeaderCoinPoints};
  tempPoints.current["Farmer"] = {...tempPoints.current["Farmer"], [round]: farmerCoinPoints};
  setPoints(tempPoints.current);

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";

  let policyMakerPointsTotal = 0;
  let communityLeaderPointsTotal = 0;
  let farmerPointsTotal = 0;
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
      policyMakerPointsTotal = policyMakerPointsTotal + parseInt(rounds[1] as string);
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
      communityLeaderPointsTotal = communityLeaderPointsTotal + parseInt(rounds[1] as string);
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
      farmerPointsTotal = farmerPointsTotal + parseInt(rounds[1] as string);
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let winners = '';  
  var arr = [policyMakerPointsTotal, communityLeaderPointsTotal, farmerPointsTotal];
  var largest = arr[0];
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] > largest ) 
      largest = arr[i];
  }  
  var indices = [];  
  let idx = arr.indexOf(largest);
  while (idx !== -1) {
    let extender = "";
    if(winners !== '')
      extender = " & ";
    if(idx == 0)
      winners = winners+extender+"Policy Maker";
    else if(idx == 1)
      winners = winners+extender+"Community Leader";
    else if(idx == 2)
      winners = winners+extender+"Farmer";
    idx = arr.indexOf(largest, idx + 1);
  }
  
  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Climate risk management score</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+'+'+benefits['Policy Maker'].climate_adaptation+'-'+set_backs['Policy Maker'].cost+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'}  style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+'+'+benefits['Community Leader'].climate_adaptation+'-'+set_backs['Community Leader'].cost+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'}  style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+'+'+benefits['Farmer'].climate_adaptation+'-'+set_backs['Farmer'].cost+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">
          
          <div className="cell cell3vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('FinalResult','FinalScores'))} />
          </div>

          <div className="cell cell3vmerge cell2hmerge flex-column">            
            
            <div className="flex flex-row justify-content-center align-self-center" style={{width: '35%'}}>
            <div className="flex flex-row align-items-center justify-content-center p-2 m-0" style={{ width: '100%', height: '4vw', borderRadius: '5px', background: visuals['Overall'].color, color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
              <i className="fa-solid fa-sun-plant-wilt" style={{fontSize: '2rem', margin: '5px'}}></i>
              <p dangerouslySetInnerHTML={{__html: displayCropEffect}} />
            </div>
          </div>

          <div className="p-1 m-0 pl-5 pr-5 mb-2" style={{ fontFamily: 'AgroAdapt', color: '#235F46', textAlign: 'center', backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px' }}>
            
            <div className="p-0 m-0 pl-5 pr-5 mb-2 flex flex-row" style={{ width: '90%', fontFamily: 'AgroAdapt', color: '#235F46', textAlign: 'center' }}>
              <div className="z-1 flex flex-row align-items-center justify-content-center p-0 m-0" style={{ transform: "rotate(-15deg)", width: '50px', height: '50px', borderRadius: '50%', background: visuals['Overall'].color, color: 'white', textAlign: 'center', fontSize: '1.5vw', fontWeight: '900' }} >
                {overall_score}
              </div>
              <div className="flex flex-column" style={{width: '100%'}}>
                <h3 style={{ padding: '0', margin: '0', fontSize: '1.25vw', fontWeight: '900'}}>Cropland Climate Resilience</h3>
                <p style={{ fontSize: '1vw'}}>Strategies had {overall} effect on crop resilience</p>
              </div>
            </div>            
            <div className="p-0 m-0 pl-5 pr-5 mb-2 flex flex-row" style={{ width: '90%', fontFamily: 'AgroAdapt', color: '#235F46', textAlign: 'center' }}>
              <div className="z-1 flex flex-row align-items-center justify-content-center p-0 m-0" style={{ transform: "rotate(-15deg)", width: '50px', height: '50px', borderRadius: '50%', background: visuals['SDG2'].color, color: 'white', textAlign: 'center', fontSize: '1.5vw', fontWeight: '900' }} >
                {SDG2_score}
              </div>
              <div className="flex flex-column" style={{width: '100%'}}>
                <h3 style={{ padding: '0', margin: '0', fontSize: '1.25vw', fontWeight: '900'}}>SDG 2 Progress</h3>
                <p style={{ fontSize: '1vw'}}>{SDG2} - Further enhancements could ensure food access for all.</p>
              </div>
            </div>                       
            <div className="p-0 m-0 pl-5 pr-5 mb-2 flex flex-row" style={{ width: '90%', fontFamily: 'AgroAdapt', color: '#235F46', textAlign: 'center' }}>
              <div className="z-1 flex flex-row align-items-center justify-content-center p-0 m-0" style={{ transform: "rotate(-15deg)", width: '50px', height: '50px', borderRadius: '50%', background: visuals['SDG13'].color, color: 'white', textAlign: 'center', fontSize: '1.5vw', fontWeight: '900' }} >
                {SDG13_score}
              </div>
              <div className="flex flex-column" style={{width: '100%'}}>
                <h3 style={{ padding: '0', margin: '0', fontSize: '1.25vw', fontWeight: '900'}}>SDG 13 Progress​</h3>
                <p style={{ fontSize: '1vw'}}>{SDG13} - Efforts needed to reduce impact on agriculture.</p>
              </div>
            </div>
            <div className="p-0 m-0 pl-5 pr-5 mb-2" style={{ textAlign: 'center', fontSize: '1.25vw', fontWeight: '700' }}>
              <div className="flex flex-row gap-2">
                <div className="flex flex-row align-items-center justify-contents-center">
                  <i className="fa-solid fa-user-pen"></i>Policy Maker &nbsp;
                  <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                    {policyMakerPointsTotal}                
                  </div>
                </div>
                <div className="flex flex-row align-items-center justify-contents-center">
                  <i className="fa-solid fa-people-group"></i>Community Leader &nbsp;
                  <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                    {communityLeaderPointsTotal}                
                  </div>
                </div>
                <div className="flex flex-row align-items-center justify-contents-center">
                  <i className="fa-solid fa-person-digging"></i>Farmer &nbsp;
                  <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                    {farmerPointsTotal}
                  </div>
                </div>                
              </div>              
            </div>

          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-column">
              <div style={{display: 'none'}} className="hidden gap-2">
                <div className="flex flex-column align-items-center justify-contents-center">
                  <div className="flex flex-row align-items-center justify-contents-center">
                    <i className="fa-solid fa-user-pen"></i>Policy Maker &nbsp;
                    <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                      {policyMakerPoints}
                    </div>
                  </div>
                  {!finalRound && <Controller
                        name="PMSupplement"
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">Supplementary Budget:</span>
                                <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-xs p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPMSupplement(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" defaultValue={50} required />
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">{policyMakerSupplement < 0 && policyMakerSupplement}</span>
                            </div>
                        )}
                    />}
                </div>
                <div className="flex flex-column align-items-center justify-contents-center">
                  <div className="flex flex-row align-items-center justify-contents-center">
                    <i className="fa-solid fa-people-group"></i>Community Leader &nbsp;
                    <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                      <i className="fa-solid fa-coins"></i>{communityLeaderPoints}                
                    </div>
                  </div>
                  {!finalRound && <Controller
                        name="CLSupplement"
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">Supplementary Budget:</span>
                                <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-xs p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCLSupplement(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" defaultValue={30} required />                              
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">{communityLeaderSupplement < 0 && communityLeaderSupplement}</span>
                            </div>
                        )}
                    />}
                </div>
                <div className="flex flex-column align-items-center justify-contents-center">
                  <div className="flex flex-row align-items-center justify-contents-center">
                    <i className="fa-solid fa-person-digging"></i>Farmer &nbsp;
                    <div className="flex flex-row align-items-center justify-content-center p-0 m-0" style={{ width: '50px', height: '50px', borderRadius: '50%', background:  '#7CB9E8', color: 'white', textAlign: 'center', fontSize: '1.25vw' }} >
                      <i className="fa-solid fa-coins"></i>{farmerPoints}
                    </div>
                  </div>
                  {!finalRound && <Controller
                        name="FSupplement"
                        control={control}
                        render={({ field, fieldState }) => (
                            <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">Supplementary Budget:</span>
                                <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-xs p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setFSupplement(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" defaultValue={20} required />                              
                                <span className="p-inputgroup-addon text-xs p-0 m-0 bg-green-100">{farmerSupplement < 0 && farmerSupplement}</span>
                            </div>
                        )}
                    />}
                </div>                
              </div>
              {!finalRound && 
                <div className="flex flex-column align-items-center justify-contents-center">
                  <div style={{display: 'none'}} className="hidden align-items-center justify-content-center gap-2" >                  
                    <Controller
                        name="resetCountry"
                        control={control}                    
                        render={({ field, fieldState }) => (
                            <>                            
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className="text-xs p-0 m-0" onChange={(e)=>{field.onChange(e.checked);whichChange('resetCountry',e.target.checked);}} />
                            </>
                        )}
                    />
                    &nbsp;<p>Reset Country? (Costs: 10 <i className="fa-solid fa-coins"></i>) </p>&nbsp;|&nbsp;<p>Reset Crops? (Costs: 10 <i className="fa-solid fa-coins"></i>) </p>&nbsp;
                    <Controller
                        name="resetCrop"
                        control={control}                    
                        render={({ field, fieldState }) => (
                            <>                            
                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} className="text-xs p-0 m-0" onChange={(e)=>{field.onChange(e.checked);whichChange('resetCrop',e.target.checked);}} />
                            </>
                        )}
                    />
                  </div>
                  <div className="flex align-items-center justify-content-center" style={{ width: '50%', minWidth: '300px' }}>
                    <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label={"Proceed to Round "+nextRound+"?"} icon="pi pi-fast-forward" iconPos="right" type="submit" rounded raised disabled={disableNewRound} />
                  </div>
                  <div className="flex align-items-center justify-content-center mt-5" style={{ width: '50%', minWidth: '300px' }}>
                    <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Restart the game?" icon="pi pi-fast-backward" iconPos="left" onClick={()=>(processReset(true))} rounded raised />
                  </div>
                </div>
              }
              {finalRound &&
                <div className="flex flex-row align-items-center justify-contents-center p-3 border-green-800 border-1" style={{ background: '#FFE6A5', borderRadius: '10px' }}><i className="fa-solid fa-trophy" style={{fontSize: '2vw', color: '#FFF', textShadow: '2px 2px 4px #000', letterSpacing: '2px'}}>Winner: {winners}</i></div>
              }
              
            </div>
          </form>

          </div>                    
      
      </div>
    </div>
  );
}
