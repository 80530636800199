import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const Levels = (props) => {
  const {showComponent,hideComponent,showLevels,setShowLevels,setShowCountries,setShowMission,round,selectedLevel,selectedCountry,setSelectedLevel,showRounds,setShowRounds} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }
  const processSelection = (level)=>{
    setSelectedLevel(level);
    processClick('Levels','Rounds');
  }

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Pick a Level</span>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} /></div>
        </div>
      </div>

      <div className="content">

          <div className="cell cell3hmerge">
            &nbsp;
          </div>          
          <div className="cell cell3hmerge flex-row">            
            <div className="flex flex-column align-items-center justify-content-center" style={{width: '20vw', height: 'fit-content', background: '#FFE6A5', borderRadius: '20px', padding: 20 }}>
              <p className="p-0 m-0" style={{ fontSize: '1vw', textAlign: 'center' }}><i className="pi pi-info-circle" style={{ fontSize: '2.5rem' }}></i><br/><br/>Think of Representative Concentration Pathways (RCPs) as roadmaps that guide the future of greenhouse gas levels.<br/><br/>Pick your skill level (1 to 4) based on these paths, each representing a different journey influenced by factors like population, economy, and technology.</p>              
            </div>
            <div className="flex flex-column align-items-center justify-content-center gap-6" style={{width: '50vw' }}>
              <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B", width: '75%'}} label="LEVEL 1 - Basic (RCP 2.6)" onClick={()=>(processSelection(1))} rounded raised />
              <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B", width: '75%'}} label="LEVEL 2 - Intermediate(RCP 4.5)" onClick={()=>(processSelection(2))} rounded raised />
              <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B", width: '75%'}} label="LEVEL 3 - Expert(RCP 6)" onClick={()=>(processSelection(3))} rounded raised />
              <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B", width: '75%'}} label="LEVEL 4 - Advanced (RCP 8.5)" onClick={()=>(processSelection(4))} rounded raised />
            </div>
            <div className="flex flex-column align-items-center justify-content-center" style={{width: '25vw', height: 'fit-content', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
              <img src="images/RCP.png" style={{ width: "100%" }} alt='IPCC Representative Concentration Pathways' />
            </div>
            
          </div>         
          <div className="cell cell3hmerge">
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('Levels','Configuration','Instruction'))} rounded raised />
          </div> 
      
      </div>
    </div>
  )
}
