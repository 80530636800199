import { PickCrop } from './PickCrop';
import { CropMatch } from './CropMatch';
import { CultivatedYield } from './CultivatedYield';

export const PreGame = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowLanding,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,    
    selectedCountry,
    setShowCountryProfile,
    selectedCrop,
    setSelectedCrop,
    crops,
    showPickCrop,
    setShowPickCrop,    
    setShowMissionDialog,
    showCropMatch,
    setShowCropMatch,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowPreGame,
    setShowStrategy,
    resetCrop,
    setResetCrop
    } = props;
  setShowLanding(false);
  setShowInstruction(false);
  setShowConfiguration(false); 
  //setShowPreGame(false);
  setShowStrategy(false);  
  if(showPickCrop === false && showCropMatch === false && showCultivatedYield === false){
    if(round > 1){
      if(resetCrop)
        setShowPickCrop(true);
      else
        setShowCropMatch(true);
    }    
    else
      setShowPickCrop(true);
  }
  return (
    <>
      {showPickCrop && <PickCrop
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration}
                              setShowCountryProfile={setShowCountryProfile}
                              round={round}
                              selectedLevel={selectedLevel}                              
                              selectedCountry={selectedCountry}
                              selectedCrop={selectedCrop}
                              setSelectedCrop={setSelectedCrop}
                              setShowPickCrop={setShowPickCrop}
                              setShowMissionDialog={setShowMissionDialog}
                              setShowCropMatch={setShowCropMatch}  
                              CropProfiles={CropProfiles}
                              benefits={benefits}
                              policyMakerStrategy={policyMakerStrategy}
                              setPolicyMakerStrategy={setPolicyMakerStrategy}
                              communityLeaderStrategy={communityLeaderStrategy}
                              setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                              farmerStrategy={farmerStrategy}
                              setFarmerStrategy={setFarmerStrategy}
                              setShowPreGame={setShowPreGame}
                              resetCrop={resetCrop}
                              setResetCrop={setResetCrop}             
                              />}
      {showCropMatch && <CropMatch
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration}
                              setShowCountryProfile={setShowCountryProfile}
                              round={round}
                              selectedLevel={selectedLevel}                              
                              selectedCountry={selectedCountry}
                              selectedCrop={selectedCrop}
                              setSelectedCrop={setSelectedCrop}
                              crops={crops}
                              setShowPickCrop={setShowPickCrop}
                              setShowMissionDialog={setShowMissionDialog}
                              showCropMatch={showCropMatch}
                              setShowCropMatch={setShowCropMatch}
                              CountryProfiles={CountryProfiles}
                              CropProfiles={CropProfiles}
                              benefits={benefits}
                              setBenefits={setBenefits}
                              setShowPreGame={setShowPreGame}
                              resetCrop={resetCrop}
                              setResetCrop={setResetCrop}        
                              />}
       {showCultivatedYield && <CultivatedYield
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration}
                              setShowCountryProfile={setShowCountryProfile}
                              round={round}
                              selectedLevel={selectedLevel}                              
                              selectedCountry={selectedCountry}
                              selectedCrop={selectedCrop}
                              setSelectedCrop={setSelectedCrop}
                              crops={crops}
                              setShowPickCrop={setShowPickCrop}
                              setShowMissionDialog={setShowMissionDialog}
                              showCropMatch={showCropMatch}
                              setShowCropMatch={setShowCropMatch}
                              CountryProfiles={CountryProfiles}
                              CropProfiles={CropProfiles}
                              benefits={benefits}
                              setBenefits={setBenefits}
                              showCultivatedYield={showCultivatedYield}
                              setShowCultivatedYield={setShowCultivatedYield}
                              setShowPreGame={setShowPreGame}          
                              />}
    </>
  )
}
