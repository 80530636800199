import { Button } from 'primereact/button';
import { useState } from 'react';

export const Goals2 = (props) => {
  const {showComponent,hideComponent,setShowMission,setShowGoals,setShowGoals2,setShowLetsPlay,setShowConfiguration} = props;

  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Your Goals</span>
        </div>
        <div className="cell">
          &nbsp;
        </div>
      </div>

      <div className="content">

          <div className="cell cell3hmerge">
            Safeguard the productivity of your cropland and guarantee the food security of your region.
          </div>          
          <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Goals2','Goals'))} />
          </div>
          <div className="cell flex-column">            
                        
            <div className="flex flex-row align-items-start gap-8">
              <div className="flex flex-column align-items-center" style={{ width: '20vw', height: '55vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw', opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8}}><img src='images/agriculture.png' alt='Agricultural Productivity' style={{ padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Reduce crop loss</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Reduce crop loss with smart strategies, thrive, and avoid climatic disasters in this game.</p>
              </div>
              <div className="flex flex-column align-items-center" style={{ width: '20vw', height: '55vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw', opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8 }}><img src='images/SDG13.png' alt='Climate Action' style={{ padding: 5 }} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Take climate action</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Address the urgent challenge of climate change in agriculture to protect your farm.</p>
              </div>
              <div className="flex flex-column align-items-center" style={{ width: '20vw', height: '55vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw',opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8}}><img src='images/SDG2.png' alt='Food Security' style={{ padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Ensure food security</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Act to end hunger, ensure food security and promote sustainable agriculture.</p>
              </div>              
            </div>
            
          </div>
          <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Goals2','LetsPlay'))} />
          </div>
          <div className="cell cell3hmerge">
            &nbsp;
          </div> 
      
      </div>
    </div>
  )
}