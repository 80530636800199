import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const Rounds = (props) => {
  const {showComponent,hideComponent,showLevels,setShowLevels,setShowCountries,setShowMission,round,selectedLevel,selectedCountry,setSelectedLevel,showRounds,setShowRounds,resetCountry,showCountryProfile,setShowCountryProfile} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }
  const processSelection = ()=>{    
    if(round > 1){
      if(resetCountry)
        processClick('Rounds','Countries');
      else
        processClick('Rounds','CountryProfile');
    }    
    else
      processClick('Rounds','Countries');
  }

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Round</span>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
        </div>
      </div>

      <div className="content">
           
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Rounds','Levels'))} />
          </div>         
          <div className="cell cell2vmerge flex-column">
            <div className="flex flex-row"  style={{ width: '100%'}}>
              <div className="flex" style={{ width: '25%'}}>
                <div className="flex flex-column align-items-center justify-content-center" style={{width: '17vw', height: 'fit-content', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>            
                  <p className="p-0 m-0" style={{ fontSize: '1vw', textAlign: 'center' }}><i className="pi pi-info-circle" style={{ fontSize: '2.5rem' }}></i><br/><br/>The game progresses in rounds, each spanning 30 years.<br/><br/>Note that significant changes in the climate variables of the region occur with the start of each new round.<br/><br/>Unlock subsequent rounds by successfully completing the previous ones.</p>             
                </div>
              </div>
              <div className="flex flex-column" style={{ width: '75%'}}>
                <div className="flex flex-column mt-2 mb-4" style={round >= 1?{opacity: '100%'}:{opacity: '30%'}}>
                  <div className="flex flex-row align-items-center justify-content-center" style={{ fontFamily: 'AgroAdapt', color: '#246046', fontSize: '22px', textAlign: 'center' }}>1980 <div className='align-items-center justify-content-center p-0 m-0' style={{height: '15px', width: '15px', background: 'green', borderRadius: '50%'}}></div> <div style={{margin: '5px 0',width: '90%', height: '2px', background: 'repeating-linear-gradient(90deg,green 0 10px,#0000 0 12px)'}}></div></div>
                  <div className="flex flex-row align-items-center justify-content-center"><Button className="m-1" style={{backgroundColor: "green", color: "white"}} label="Round 1" rounded disabled /></div>
                </div>
                <div className="flex flex-column mt-4 mb-4" style={round >= 2?{opacity: '100%'}:{opacity: '30%'}}>
                  <div className="flex flex-row align-items-center justify-content-center" style={{ fontFamily: 'AgroAdapt', color: '#246046', fontSize: '22px', textAlign: 'center' }}>2010 <div className='align-items-center justify-content-center p-0 m-0' style={{height: '15px', width: '15px', background: 'green', borderRadius: '50%'}}></div> <div style={{margin: '5px 0',width: '90%', height: '2px', background: 'repeating-linear-gradient(90deg,green 0 10px,#0000 0 12px)'}}></div></div>
                  <div className="flex flex-row align-items-center justify-content-center"><Button className="m-1" style={{backgroundColor: "green", color: "white"}} label="Round 2" rounded disabled /></div>
                </div>
                <div className="flex flex-column mt-4 mb-2" style={round >= 3?{opacity: '100%'}:{opacity: '30%'}}>
                  <div className="flex flex-row align-items-center justify-content-center" style={{ fontFamily: 'AgroAdapt', color: '#246046', fontSize: '22px', textAlign: 'center' }}>2040 <div className='align-items-center justify-content-center p-0 m-0' style={{height: '15px', width: '15px', background: 'green', borderRadius: '50%'}}></div> <div style={{margin: '5px 0',width: '90%', height: '2px', background: 'repeating-linear-gradient(90deg,green 0 10px,#0000 0 12px)'}}></div></div>
                  <div className="flex flex-row align-items-center justify-content-center"><Button className="m-1" style={{backgroundColor: "green", color: "white"}} label="Round 3" rounded disabled /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="cell cell2vmerge">            
            {round > 1?<Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Rounds','CountryProfile'))} />:<Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Rounds','Countries'))} />}
          </div>
          <div className="cell cell3hmerge">
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('Rounds','Configuration','Instruction'))} rounded raised />
          </div> 
      
      </div>
    </div>
  )
}
