import { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const FinalScores = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    setRound,
    selectedLevel,
    setSelectedLevel,
    selectedCountry,
    setSelectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,    
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    disaster,
    disasters,
    setDisasters,
    showDisasterResult,
    setShowDisasterResult,
    SetBacks,
    set_backs,
    setSet_backs,
    showSetBackSelection,
    setShowSetBackSelection,
    showFinalScores,
    setShowFinalScores,
    resetCountry,
    setResetCountry,
    resetCrop,
    setResetCrop
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let threshold = {upper: 80,lower: 45};
  let overall_score = Math.ceil((scores['Policy Maker'].climate_adaptation_score+scores['Community Leader'].climate_adaptation_score+scores['Farmer'].climate_adaptation_score)/3);
  let SDG2_score = Math.ceil((((scores['Policy Maker'].social_score+scores['Community Leader'].social_score+scores['Farmer'].social_score)/3)+((scores['Policy Maker'].economic_score+scores['Community Leader'].economic_score+scores['Farmer'].economic_score)/3))/2);
  let SDG13_score = Math.ceil((scores['Policy Maker'].environmental_score+scores['Community Leader'].environmental_score+scores['Farmer'].environmental_score)/3);
  let overall = calculateScore(overall_score,threshold);
  let SDG2 = calculateScore(SDG2_score,threshold);
  let SDG13 = calculateScore(SDG13_score,threshold);
  let visuals = {"Overall": returnVisuals(overall),
                 "SDG2": returnVisuals(SDG2),
                 "SDG13": returnVisuals(SDG13)};
    
  let lossAfterStrategies = {};  
  Object.entries(crops.current.cropYield).forEach((crop)=>{    
    let yield_affected = disaster.current.crops_affected[crop[0]]+set_backs.Crops[crop[0]];    
    let strategies_effect = (((yield_affected / disaster.current.crop_production[crop[0]]) * 100) * (100 - overall_score)) / 100;
    let crop_loss_after_strategies = (strategies_effect / 100) * disaster.current.crop_production[crop[0]];
    lossAfterStrategies = {...lossAfterStrategies, [crop[0]]: crop_loss_after_strategies};
  });

  disaster.current = {...disaster.current, mitigated_loss: lossAfterStrategies};

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social+benefits['Policy Maker'].climate_adaptation-set_backs['Policy Maker'].cost;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social+benefits['Community Leader'].climate_adaptation-set_backs['Community Leader'].cost;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social+benefits['Farmer'].climate_adaptation-set_backs['Farmer'].cost;

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
        <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Climate risk management score</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+'+'+benefits['Policy Maker'].climate_adaptation+'-'+set_backs['Policy Maker'].cost+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+'+'+benefits['Community Leader'].climate_adaptation+'-'+set_backs['Community Leader'].cost+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+'+'+benefits['Farmer'].climate_adaptation+'-'+set_backs['Farmer'].cost+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">
          
          <div className="cell cell3vmerge">
            <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#ddd", color: "#333"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('FinalScores','SetBackSelection'))} />
          </div>

          <div className="cell cell3vmerge flex-column">            
            
            <div className="flex flex-column">
              <p>Your strategies have saved {((disaster.current.crops_affected[crops.current['cropNames'][0].toLowerCase()]+set_backs.Crops[crops.current['cropNames'][0].toLowerCase()])-(lossAfterStrategies[crops.current['cropNames'][0].toLowerCase()])).toLocaleString()} tonnes of {crops.current['cropNames'][0]} and {((disaster.current.crops_affected[crops.current['cropNames'][1].toLowerCase()]+set_backs.Crops[crops.current['cropNames'][1].toLowerCase()])-(lossAfterStrategies[crops.current['cropNames'][1].toLowerCase()])).toLocaleString()} tonnes of {crops.current['cropNames'][1]} loss.</p>
              
            </div>

            <div className="flex flex-row gap-1">
              <div className="p-1 m-0 pl-5 pr-5 mb-2 flex flex-row" style={{ textAlign: 'center', backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px' }}>
                <div className="z-1 flex align-items-center justify-content-center p-0 m-0" style={{width: '100px', height: '100px', borderRadius: '50%', background: visuals['Overall'].color, color: 'white', textAlign: 'center', fontSize: '30pt', fontWeight: '900' }} >
                  {overall_score}
                </div>
                <div className="flex flex-column">
                  <h3 style={{fontSize: '1.5vw', fontWeight: '900'}}>Climate risk management</h3>
                  <p style={{fontSize: '1vw'}}>Strategies had {overall} effect on crop resilience</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-8">
              
              <div className="p-1 m-2 pl-5 pr-5 mb-2 flex flex-row col-4" style={{ textAlign: 'center', backgroundImage: 'linear-gradient(rgba(255,230,165,0), rgba(255,230,165,1))', borderRadius: '25px', maxHeight: '200px', minHeight: '100px' }}>                
                <div className="z-2 flex align-items-center justify-content-center p-2 m-0" style={{width: '4vw', height: '4vw', borderRadius: '50%', background: visuals['SDG2'].color, color: 'white', textAlign: 'center', fontSize: '2vw', fontWeight: '900' }} >
                  {SDG2_score}
                </div>                
                <div className="flex flex-column justify-content-center">
                  <div className="p-0 m-0 z-1 relative" style={{ top: '-50px' }}>
                    <img src='images/SDG2.png' alt='Food Security' style={{ width: '15vh'}} className="align-self-center" />
                  </div>                  
                  <div className="p-0 m-0 relative" style={{ top: '-50px' }}>
                    <p style={{fontSize: '1.5vw', margin: 0, padding: 0, fontWeight: 'bolder', color: '#235F46', textShadow: '2px 2px 4px #fff'}}>{SDG2} - Further enhancements could ensure food access for all.</p>
                  </div>
                </div>                
              </div>
              <div className="col-2">
                &nbsp;
              </div>
              <div className="p-1 m-2 pl-5 pr-5 mb-2 flex flex-row col-4" style={{ fontFamily: 'AgroAdapt', color: '#235F46', textAlign: 'center', backgroundImage: 'linear-gradient(rgba(255,230,165,0), rgba(255,230,165,1))', borderRadius: '25px', maxHeight: '200px', minHeight: '100px' }}>
                <div className="z-2 flex align-items-center justify-content-center p-2 m-0" style={{width: '4vw', height: '4vw', borderRadius: '50%', background: visuals['SDG13'].color, color: 'white', textAlign: 'center', fontSize: '2vw', fontWeight: '900' }} >
                  {SDG13_score}
                </div>
                <div className="flex flex-column justify-content-center">
                  <div className="p-0 m-0 z-1 relative" style={{ top: '-50px' }}>
                    <img src='images/SDG13.png' alt='Climate Action' style={{ width: '15vh'}} className="align-self-center" />
                  </div>
                  <div className="p-0 m-0 relative" style={{ top: '-50px' }}>
                    <p style={{fontSize: '1.5vw', margin: 0, padding: 0, fontWeight: 'bolder', color: '#235F46', textShadow: '2px 2px 4px #fff'}}>{SDG13} - Efforts needed to reduce impact on agriculture.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="cell cell3vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('FinalScores','FinalResult'))} />
          </div>           
      
      </div>
    </div>
  );
}
