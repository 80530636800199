import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,calculateScore,returnVisuals } from '../../../App';

export const CooperationScore = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards    
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let threshold = {upper: 3,lower: 2};
  let policyMakerStrategies = {1: policyMakerStrategy[1], 2: policyMakerStrategy[2], 3: policyMakerStrategy[3], 4: policyMakerStrategy[4], 5: policyMakerStrategy[5]};
  let communityLeaderStrategies = {1: communityLeaderStrategy[1], 2: communityLeaderStrategy[2], 3: communityLeaderStrategy[3], 4: communityLeaderStrategy[4], 5: communityLeaderStrategy[5]};
  let farmerStrategies = {1: farmerStrategy[1], 2: farmerStrategy[2], 3: farmerStrategy[3], 4: farmerStrategy[4], 5: farmerStrategy[5]};

  const [cooperation,setCooperation] = useState<any>({"Policy Maker-Community Leader": calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length,threshold),
                                                      "Policy Maker-Farmer": calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length,threshold),
                                                      "Community Leader-Farmer": calculateScore(intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length,threshold)});

  let coinBenefit = 10;
  useEffect(()=>{
    setCooperation({"Policy Maker-Community Leader": calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length,threshold),
                    "Policy Maker-Farmer": calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length,threshold),
                    "Community Leader-Farmer": calculateScore(intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length,threshold)});
    let tempBenefits = {
      "Policy Maker": {cooperation: 0},
      "Community Leader": {cooperation: 0},
      "Farmer": {cooperation: 0}
    }
    if(calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length,threshold) === "Good")
      tempBenefits = {...tempBenefits, "Policy Maker": {cooperation: tempBenefits['Policy Maker'].cooperation+coinBenefit},"Community Leader": {cooperation: tempBenefits['Community Leader'].cooperation+coinBenefit}};
    if(calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length,threshold) === "Good")
      tempBenefits = {...tempBenefits, "Policy Maker": {cooperation: tempBenefits['Policy Maker'].cooperation+coinBenefit},"Farmer": {cooperation: tempBenefits['Farmer'].cooperation+coinBenefit}};
    if(calculateScore(intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length,threshold) === "Good")
      tempBenefits = {...tempBenefits, "Community Leader": {cooperation: tempBenefits['Community Leader'].cooperation+coinBenefit},"Farmer": {cooperation: tempBenefits['Farmer'].cooperation+coinBenefit}};
    setBenefits({...benefits, ...tempBenefits});
  },[calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length,threshold),calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length,threshold),calculateScore(intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length,threshold)]);

  let overall = calculateScore((intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length+intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length+intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length)/3,threshold);  
  let visuals = {"Policy Maker-Community Leader": returnVisuals(calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(communityLeaderStrategies)).length,threshold)),
                 "Policy Maker-Farmer": returnVisuals(calculateScore(intersect(Object.values(policyMakerStrategies), Object.values(farmerStrategies)).length,threshold)),
                 "Community Leader-Farmer": returnVisuals(calculateScore(intersect(Object.values(communityLeaderStrategies), Object.values(farmerStrategies)).length,threshold)),
                 "Overall": returnVisuals(overall)};
  
  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation;

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Cooperation Score</span>
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center' }}>The Cooperation Score gauges how well players align their strategic choices towards a shared agricultural resilience goal.</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">
        
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('CooperationScore','StrategyResults'))} />
        </div>         
        <div className="cell cell2vmerge flex-column">
          <table style={{ margin: 0, padding: 0 }}>
            <tbody>
              <tr>
                <td style={{ width: '170px', height: '250px' }} rowSpan={2}>
                  <div className="flex flex-column gap-0 m-0 p-0" style={{ transform: "rotate(-45deg)" }}>                    
                    <div className="flex flex-wrap align-items-center justify-content-center p-2 m-0" style={{ backgroundColor: visuals['Policy Maker-Community Leader'].color, borderRadius: '20%', width: '80px', height: '80px' }}>
                      <p className="p-0 m-0" style={{ color: '#fff', fontSize: '13pt', fontWeight: 'bold', textAlign: 'center' }}><i className={visuals['Policy Maker-Community Leader'].icon} style={{ fontSize: '1.5rem' }}></i><br/>{cooperation['Policy Maker-Community Leader']}</p>
                    </div>
                    <span style={{ width: '100%', fontSize: '100px', color: '#246046' }}>&#10231;</span>
                  </div>
                </td>
                <td style={{ width: '250px', textAlign: 'center' }}>
                  <div className="flex flex-row align-self-center align-items-center justify-content-center pt-2 pb-2 mt-2" style={{ height: '70px', width: '70%', background: '#FFE6A5', color: '#246046', borderRadius: '25px', margin: 'auto' }}><i className='fa fa-user-pen m-1 p-0' style={{ fontSize: '1.5rem' }} /><p className="p-0 m-0" style={{ fontWeight: '700', fontSize: '1.5rem' }}>Policy Maker</p></div>
                </td>
                <td style={{ width: '170px', height: '250px', paddingLeft: '60px', paddingTop: '50px' }} rowSpan={2}>
                  <div className="flex flex-column gap-0 m-0 p-0" style={{ transform: "rotate(45deg)", margin: 'auto' }}>                    
                    <div className="flex flex-wrap align-items-center justify-content-center p-2 m-0" style={{ backgroundColor: visuals['Policy Maker-Farmer'].color, borderRadius: '20%', width: '80px', height: '80px' }}>
                      <p className="p-0 m-0" style={{ color: '#fff', fontSize: '13pt', fontWeight: 'bold', textAlign: 'center' }}><i className={visuals['Policy Maker-Farmer'].icon} style={{ fontSize: '1.5rem' }}></i><br/>{cooperation['Policy Maker-Farmer']}</p>
                    </div>
                    <span style={{ width: '100%', fontSize: '100px', color: '#246046' }}>&#10231;</span>
                  </div>
                </td>
              </tr>
              <tr>                
                <td style={{ width: 'fit-contents', textAlign: 'center' }}>
                  <div className="z-0 flex flex-row align-self-center align-items-center justify-content-center pt-1 pb-1 mt-1" style={{ height: '40px', maxHeight: '100%', width: '100%', background: visuals['Overall'].color, color: '#fff', borderRadius: '15px', margin: 'auto'  }}><p className="p-0 m-0" style={{ fontSize: '18px', fontWeight: '700' }}>Overall Result: {overall} <i className={visuals['Overall'].icon+' m-1 p-0'} /></p></div>
                  {(benefits['Policy Maker'].cooperation > 0 || benefits['Community Leader'].cooperation > 0 || benefits['Farmer'].cooperation > 0) && 
                  <>                    
                    <div className="z-1 absolute flex flex-column align-items-center justify-content-center gap-2 p-4 mb-2" style={{ left: '80px', top: '320px', transform: "rotate(-5deg)", backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px', width: '270px' }}>                      
                      <p className="p-0 m-0" style={{ color: '#235F46', fontSize: '12pt', textAlign: 'center', fontWeight: '900px' }}>{benefits['Policy Maker'].cooperation > 0? 'Policy Maker ': ''}{benefits['Community Leader'].cooperation > 0? 'Community Leader ': ''}{benefits['Farmer'].cooperation > 0? 'Farmer ': ''}had the great cooperation!!</p>
                    </div>
                    <div className="z-2 absolute flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ left: '320px', top: '320px', transform: "rotate(15deg)", backgroundColor: '#235F46', borderRadius: '50%', width: '120px', height: '120px' }}>
                      <p className="p-0 m-0" style={{ color: '#fff', fontSize: '10pt', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-smile-beam" style={{ fontSize: '1.5rem' }}></i><br/>Good job! each player gets 10 coins</p>
                    </div>
                  </>
                  }
                </td>                
              </tr>
              <tr>
                <td style={{ width: '200px', textAlign: 'center' }}>
                  <div className="flex flex-row align-self-center align-items-center justify-content-center pt-2 pb-2 mt-2" style={{ height: '70px', width: '100%', background: '#FFE6A5', color: '#246046', borderRadius: '25px', margin: 'auto'  }}><i className='fa fa-people-group m-1 p-0' style={{ fontSize: '1.5rem' }} /><p className="p-0 m-0" style={{ fontWeight: '700', fontSize: '1.5rem' }}>Community Leader</p></div>
                </td>
                <td style={{ width: '300px', textAlign: 'center' }}>
                  <div className="flex flex-column align-items-center justify-contents-center">
                    <span style={{ width: '100%', fontSize: '100px', color: '#246046' }}>&#10231;</span>
                    <div className="flex flex-wrap align-items-center justify-content-center p-2 m-0" style={{ backgroundColor: visuals['Community Leader-Farmer'].color, borderRadius: '10%', width: '80px', height: '80px' }}>
                      <p className="p-0 m-0" style={{ color: '#fff', fontSize: '13pt', fontWeight: 'bold', textAlign: 'center' }}><i className={visuals['Community Leader-Farmer'].icon} style={{ fontSize: '1.5rem' }}></i><br/>{cooperation['Community Leader-Farmer']}</p>
                    </div>
                  </div>
                </td>
                <td style={{ width: '200px', textAlign: 'center' }}>
                  <div className="flex flex-row align-self-center align-items-center justify-content-center pt-2 pb-2 mt-2" style={{ height: '70px', width: '70%', background: '#FFE6A5', color: '#246046', borderRadius: '25px', margin: 'auto' }}><i className='fa fa-person-digging m-1 p-0' style={{ fontSize: '1.5rem' }} /><p className="p-0 m-0" style={{ fontWeight: '700', fontSize: '1.5rem' }}>Farmer</p></div>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('CooperationScore','Evaluation'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('CooperationScore','Strategy','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('CooperationScore','Strategy','Configuration'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Strategies" onClick={()=>(processClick('CooperationScore','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
