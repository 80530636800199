import { Button } from 'primereact/button';
import { useState } from 'react';

export const LetsPlay = (props) => {
  const {showComponent,hideComponent,setShowMission,setShowGoals2,setShowLetsPlay,setShowConfiguration} = props;

  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          &nbsp;
        </div>
        <div className="cell">
          &nbsp;
        </div>
      </div>

      <div className="content">
                   
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('LetsPlay','Goals2'))} />
          </div>
          <div className="cell flex-column cell2vmerge">            
            <p style={{ fontSize: '3vw', textAlign: 'center', fontWeight: '400', padding: '0px', margin: '0px', paddingTop: '10px' }}>Let's play, use your board game and<br/>this online interface!</p>            
          </div>
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processTransition('LetsPlay','Instruction','Configuration'))} />
          </div>
          <div className="cell cell3hmerge align-contents-center justify-contents-center">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1 " style={{ backgroundColor: "#FFFCF3", color: "#38BA8B", fontSize: "20px", border: "1px solid #38BA8B" }} label="start the game" onClick={()=>(processTransition('LetsPlay','Instruction','Configuration'))} rounded raised />
          </div> 
      
      </div>
    </div>
  )
}