import { useEffect, useState, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const SetBackSelection = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    disaster,
    disasters,
    setDisasters,
    showDisasterResult,
    setShowDisasterResult,
    showFinalScores,
    setShowFinalScores,
    SetBacks,
    set_backs,
    setSet_backs,
    showSetBackSelection,
    setShowSetBackSelection
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  
  const generateSetBacks = (AllSetBacks)=>{
    let setBacks = [];
    let number_setBacks = randomIntFromInterval(1,3);
    let i = 1;
    while(i <= number_setBacks) {
      let tempSelectedSetBack = randomIntFromInterval(0,SetBacks.current.length);
      if(AllSetBacks.find((setBack) => setBack === tempSelectedSetBack) === undefined){
        if(setBacks.find((setBack) => setBack === tempSelectedSetBack) === undefined){
          setBacks.push(tempSelectedSetBack);
          i++;
        }
      }
    }
    return(setBacks);
  }
  
  let AllSetBacks = [];
  let policyMakerSetBacks = generateSetBacks(AllSetBacks);
  AllSetBacks = [...AllSetBacks, ...policyMakerSetBacks];
  let communityLeaderSetBacks = generateSetBacks(AllSetBacks);
  AllSetBacks = [...AllSetBacks, ...communityLeaderSetBacks];
  let farmerSetBacks = generateSetBacks(AllSetBacks);
  AllSetBacks = [...AllSetBacks, ...farmerSetBacks];

  //const displaySetBackDetails = useRef<any>(``);
  const policyMakerSetBackDetails = useRef<any>(``);
  const communityLeaderSetBackDetails = useRef<any>(``);
  const farmerSetBackDetails = useRef<any>(``);
  const productionEffect = useRef<any>(``);
  const [triggerSetBack,setTriggerSetBack] = useState(true);
  let tempSetBacksList = SetBacks.current;
  
  useEffect(()=>{
    if(triggerSetBack){      
      let i = 1;
      let cost = 0;      
      let displayPolicyMakerSetBacks = ``;
      policyMakerSetBacks.map((SetBack)=>{
        if(tempSetBacksList[SetBack] !== undefined){          
          displayPolicyMakerSetBacks = displayPolicyMakerSetBacks+(SetBack+1)+`. `+tempSetBacksList[SetBack]['set_back']+` (-`+tempSetBacksList[SetBack]['cost']+`<i class="fa-solid fa-coins"></i>)<br/>`;
          cost = cost+tempSetBacksList[SetBack]['cost'];          
          i++;
        }
      });      
      let policyMakerSetBackCost = cost;

      i = 1;
      cost = 0;      
      let displayCommunityLeaderSetBacks = ``;
      communityLeaderSetBacks.map((SetBack)=>{
        if(tempSetBacksList[SetBack] !== undefined){          
          displayCommunityLeaderSetBacks = displayCommunityLeaderSetBacks+(SetBack+1)+`. `+tempSetBacksList[SetBack]['set_back']+` (-`+tempSetBacksList[SetBack]['cost']+`<i class="fa-solid fa-coins"></i>)<br/>`;
          cost = cost+tempSetBacksList[SetBack]['cost'];
          i++;
        }
      });      
      let communityLeaderSetBackCost = cost;

      i = 1;
      cost = 0;      
      let displayFarmerSetBacks = ``;
      farmerSetBacks.map((SetBack)=>{        
        if(tempSetBacksList[SetBack] !== undefined){          
          displayFarmerSetBacks = displayFarmerSetBacks+(SetBack+1)+`. `+tempSetBacksList[SetBack]['set_back']+` (-`+tempSetBacksList[SetBack]['cost']+`<i class="fa-solid fa-coins"></i>)<br/>`;
          cost = cost+tempSetBacksList[SetBack]['cost'];
          i++;
        }
      });      
      let farmerSetBackCost = cost;

      let tempYield = {};
      let tempLoss = {};
      let cropEffect = {};
      let displayCropEffect = "";
      let displayTotalCropEffect = "";   
      Object.entries(crops.current.cropYield).forEach((crop)=>{        
        tempLoss = {...tempLoss, [crop[0]]: 0};      
        tempYield = {...tempYield, [crop[0]]: crop[1]['crop_yield']};
        let calcEventYield = tempYield[crop[0]];
        let yield_affected = Math.ceil((calcEventYield*AllSetBacks.length*3/100)/100)*100;
        tempLoss = {...tempLoss, [crop[0]]: yield_affected};
        cropEffect = {...cropEffect, [crop[0]]: tempLoss[crop[0]]};        
        //displayTotalCropEffect = displayTotalCropEffect+`<i class="fa-solid fa-sun-plant-wilt" style="font-size: 2rem; margin: '5px'"></i> `+tempLoss[crop[0]].toLocaleString()+` out of `+disaster.current.crop_production[crop[0]].toLocaleString()+` tonnes of `+crop[0]+` lost!<br/>`;
        displayTotalCropEffect = displayTotalCropEffect+` `+Math.ceil(tempLoss[crop[0]]/disaster.current.crop_production[crop[0]]).toLocaleString()+`% of `+crop[0]+` lost!<br/>`;
      });      
      policyMakerSetBackDetails.current = displayPolicyMakerSetBacks;
      communityLeaderSetBackDetails.current = displayCommunityLeaderSetBacks;
      farmerSetBackDetails.current = displayFarmerSetBacks;
      productionEffect.current = `<p style="text-align: center; padding: 0; margin: 0;"><b><u>PRODUCTION EFFECT:</u></b></p><div class="flex flex-row align-items-center justify-content-center p-0 m-0 gap-1"><i class="fa-solid fa-sun-plant-wilt" style="font-size: '50pt'; margin: '5px'"></i><p>`+displayTotalCropEffect+`</p></div>`;

      let tempSetBacks = {
        "Policy Maker": {set_backs: Object.keys(policyMakerSetBacks), cost: policyMakerSetBackCost},
        "Community Leader": {set_backs: Object.keys(communityLeaderSetBacks), cost: communityLeaderSetBackCost},
        "Farmer": {set_backs: Object.keys(farmerSetBacks), cost: farmerSetBackCost},
        "Crops": cropEffect
      }      
      setSet_backs({...set_backs, ...tempSetBacks});      
      setTriggerSetBack(false);
    }
  },[policyMakerSetBacks,communityLeaderSetBacks,farmerSetBacks]);

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social+benefits['Policy Maker'].climate_adaptation;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social+benefits['Community Leader'].climate_adaptation;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social+benefits['Farmer'].climate_adaptation;


  return (
    <div className="container" style={{ backgroundImage: "url('images/setback_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center', color: '#555' }}>Life happens!!</span>
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center', color: '#555' }}>You are facing Set Backs</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={(policyMakerCoins-set_backs['Policy Maker']?.cost).toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+'+'+benefits['Policy Maker'].climate_adaptation+'-'+set_backs['Policy Maker']?.cost+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={(communityLeaderCoins-set_backs['Community Leader']?.cost).toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+'+'+benefits['Community Leader'].climate_adaptation+'-'+set_backs['Community Leader']?.cost+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={(farmerCoins-set_backs['Farmer']?.cost).toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+'+'+benefits['Farmer'].climate_adaptation+'-'+set_backs['Farmer']?.cost+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell2vmerge">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#ddd", color: "#333"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('SetBackSelection','DisasterResult'))} />
        </div>

        <div className="cell cell2vmerge flex-column">
          
        <div className="flex flex-column align-items-center justify-content-center gap-2">
          <div className="flex flex-row align-items-center justify-content-center gap-2">
            <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', backgroundImage: 'linear-gradient(rgba(25,25,25,1), rgba(25,25,25,0.5))', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', height: 'fit-content', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.25vw', textAlign: 'center', fontWeight: '900', color: '#fff' }}>Policy Maker</p>
              <div className="p-0 m-0" style={{ color: '#fff',textAlign: 'left', fontSize: '1vw', borderRadius: '25px' }} dangerouslySetInnerHTML={{__html: policyMakerSetBackDetails.current}} />
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-1 pb-1 mt-1" style={{ height: '20%', width: '70%', background: '#ddd', color: '#555', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' /><p className="p-0 m-0" style={{ fontSize: '1vw' }}>Coins Lost: {set_backs['Policy Maker']?.cost}</p></div>
            </div>
            <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', backgroundImage: 'linear-gradient(rgba(25,25,25,1), rgba(25,25,25,0.5))', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.25vw', textAlign: 'center', fontWeight: '900', color: '#fff' }}>Community Leader</p>
              <div className="p-0 m-0" style={{ color: '#fff',textAlign: 'left', fontSize: '1vw', borderRadius: '25px' }} dangerouslySetInnerHTML={{__html: communityLeaderSetBackDetails.current}} />
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-1 pb-1 mt-1" style={{ height: '20%', width: '70%', background: '#ddd', color: '#555', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' /><p className="p-0 m-0" style={{ fontSize: '1vw' }}>Coins Lost: {set_backs['Community Leader']?.cost}</p></div>
            </div>
            <div className="flex flex-column p-2" style={{width: '20vw', height: '100%', minHeight: '300px', backgroundImage: 'linear-gradient(rgba(25,25,25,1), rgba(25,25,25,0.5))', borderRadius: '20px' }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmer' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-2" style={{ fontSize: '1.25vw', textAlign: 'center', fontWeight: '900', color: '#fff' }}>Farmer</p>
              <div className="p-0 m-0" style={{ color: '#fff',textAlign: 'left', fontSize: '1vw', borderRadius: '25px' }} dangerouslySetInnerHTML={{__html: farmerSetBackDetails.current}} />
              <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-1 pb-1 mt-1" style={{ height: '20%', width: '70%', background: '#ddd', color: '#555', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' /><p className="p-0 m-0" style={{ fontSize: '1vw' }}>Coins Lost: {set_backs['Farmer']?.cost}</p></div>
            </div>
          </div>
          <div className="pl-5 pr-5 pt-1 pb-1" style={{ color: '#fff', textAlign: 'left', fontSize: '1.25vw', backgroundImage: 'linear-gradient(rgba(25,25,25,1), rgba(25,25,25,0.5))', borderRadius: '25px' }} dangerouslySetInnerHTML={{__html: productionEffect.current}} />
        </div>          

        </div>
        <div className="cell cell2vmerge">
        <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#ddd", color: "#333"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('SetBackSelection','FinalScores'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Mission" onClick={()=>(processTransition('SetBackSelection','Strategy','Instruction'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Selections" onClick={()=>(processTransition('SetBackSelection','Strategy','Configuration'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Strategies" onClick={()=>(processClick('SetBackSelection','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
