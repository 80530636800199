import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { useState } from 'react';
import { intersect,calculateScore,returnVisuals } from '../../../App';

export const Evaluation = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,    
    showCooperationScore,
    setShowCooperationScore,
    showCompleteScore,
    setShowCompleteScore
  } = props;
  
   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation;

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Evaluation</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">
        
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Evaluation','CooperationScore'))} />
        </div>
        <div className="cell cell2vmerge flex-column">
          <div className="flex flex-column align-items-center p-0 m-0 gap-2" style={{ width: '70%', borderRadius: '100px' }}>
            <div className='flex flex-row align-items-center justify-content-center gap-2'>
              <div>
                <p className="p-0 m-0" style={{ color: 'green', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>Good</p>
              </div>
              <div>
                <div className="flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ width: '10vw', height: '10vw', borderRadius: '50%', background: 'green', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>More than 80 points</p>
                </div>
              </div>
              <div style={{ background: '#FFE6A5', borderRadius: '20px' }}><p className="p-0 m-4" style={{ color: '#235F46', fontSize: '1.5vw', fontWeight: 'bold' }}>Excellent economic, environment and social strategies for resilience against climate disaster</p></div>
            </div>
            <div className='flex flex-row align-items-center justify-content-center gap-2'>
              <div>
                <p className="p-0 m-0" style={{ color: 'orange', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>Fair</p>
              </div>
              <div>
                <div className="flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ width: '10vw', height: '10vw', borderRadius: '50%', background: 'orange', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>More than 46 points</p>
                </div>
              </div>
              <div style={{ background: '#FFE6A5', borderRadius: '20px' }}><p className="p-0 m-4" style={{ color: '#235F46', fontSize: '1.5vw', fontWeight: 'bold' }}>Moderate economic, environment and social strategies for resilience against climate disaster</p></div>
            </div>
            <div className='flex flex-row align-items-center justify-content-center gap-2'>
              <div>
                <p className="p-0 m-0" style={{ color: 'red', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>Bad</p>
              </div>
              <div>
                <div className="flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ width: '10vw', height: '10vw', borderRadius: '50%', background: 'red', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>less than 45 points</p>
                </div>                
              </div>
              <div style={{ background: '#FFE6A5', borderRadius: '20px' }}><p className="p-0 m-4" style={{ color: '#235F46', fontSize: '1.5vw', fontWeight: 'bold' }}>Bad economic, environment and social strategies for resilience against climate disaster</p></div>
            </div>
          </div>          
        </div>
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Evaluation','CompleteScore'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('Evaluation','Strategy','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('Evaluation','Strategy','Configuration'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Strategies" onClick={()=>(processClick('Evaluation','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
