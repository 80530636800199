import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const CountryProfile = (props) => {
  const {showComponent,hideComponent,setShowInstruction,setShowConfiguration,round,selectedLevel,selectedCountry,CountryProfiles,resetCountry} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }
  
  return (
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>{selectedCountry}'s Profile</span>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
        </div>
      </div>

      <div className="content">
          
          <div className="cell cell2vmerge">
            {!(round > 1 && !resetCountry) && <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('CountryProfile','Countries'))} />}
          </div>         
          <div className="cell  cell2vmerge flex-column">
            <div className="relative grid grid-nogutter overflow-auto">
              <div className="col-4 flex align-items-start justify-content-center">
                <div className="flex flex-column align-items-center p-0 m-0" style={{ width: '70%', backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px' }}>            
                  <h1 className="block" style={{ fontSize: '2vw', fontWeight: 'bold' }}>Climate Risk</h1>
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-1" style={{ width:'50%' }}>
                      <img src='images/floods2.png' alt='Flood Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.5vw', textAlign: 'center' }}>Flood Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['flood']}%</h1>
                    </div>
                  </div>
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-1" style={{ width:'50%' }}>
                      <img src='images/drought2.png' alt='Drought Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.5vw', textAlign: 'center' }}>Drought Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['drought']}%</h1>
                    </div>
                  </div>
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-1" style={{ width:'50%' }}>
                      <img src='images/wildfire2.png' alt='Wildfire Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.5vw', textAlign: 'center' }}>Wildfire Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['wildfire']}%</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 flex align-items-start justify-content-center">                
                <div className="flex flex-row align-items-start gap-2 p-5 m-0" style={{ width: '95%', backgroundImage: 'linear-gradient(to left, rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px' }}> 
                  <div className="inline-block flex flex-column align-items-center justify-content-center p-0 m-0" style={{ width: '40%' }}>
                    <img src={'images/'+CountryProfiles?.current[selectedCountry]['image']+'.png'} alt={selectedCountry} style={{maxWidth: '90%'}} />
                    <h1 className="p-0 m-0" style={{ fontSize: '2vw', fontWeight: 'bold' }}>{selectedCountry}</h1>
                  </div>           
                  <div className="inline-block flex" style={{ width: '60%' }}>
                    <div className="flex flex-column p0 m0">
                      <table style={{ padding: 0, margin: 0, borderCollapse: 'collapse'}}>
                        <tbody>
                          <tr>
                            <td style={{width: '35%'}}><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Area:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5vw', textAlign: 'left', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry]['area']} Km<sup>2</sup></p></td>
                          </tr>
                          <tr>
                            <td style={{width: '35%'}}><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Crop Land Intensity:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5vw', textAlign: 'left', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry]['intensity_of_cropland']}%</p></td>
                          </tr>
                          <tr>
                            <td style={{width: '35%'}}><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Avg. temperature:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5vw', textAlign: 'left', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['temperature']} &deg; C</p></td>
                          </tr>
                          <tr>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Avg. precipitation:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5w', textAlign: 'left', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['precipitation']} mm</p></td>
                          </tr>
                          <tr>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Dominant soil type:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5vw', textAlign: 'left', fontWeight: 'bold' }}>Fertility: {CountryProfiles?.current[selectedCountry][round][selectedLevel]['soil_fertility']}<br/>Drainage: {CountryProfiles?.current[selectedCountry][round][selectedLevel]['soil_drainage']}</p></td>
                          </tr>                    
                          <tr>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1vw', textAlign: 'left' }}>Dominant Agro-ecological zones:</p></td>
                            <td><p className="m-0 p-0" style={{ color: '#000', fontSize: '1.5vw', textAlign: 'left', fontWeight: 'bold' }}>{CountryProfiles?.current[selectedCountry][round][selectedLevel]['AEZ']}</p></td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <p className="block" style={{ fontSize: '1vw', textAlign: 'left' }}>{CountryProfiles?.current[selectedCountry]['description']}</p>
                        <p className="block" style={{ color: '#000', fontSize: '1vw', fontWeight: 'bold' }}>Agricultural Productivity: {CountryProfiles?.current[selectedCountry]['productivity']}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
              <div className="cell cell3hmerge" style={{width: '100%'}}>
                <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label={round > 1? "Farm in "+selectedCountry:"Pick Crop to Farm in "+selectedCountry} icon="fa-solid fa-tractor" iconPos="top" onClick={()=>(processTransition('CountryProfile','Configuration','PreGame'))} rounded raised />
              </div>              
            </div>
            
          </div>
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processTransition('CountryProfile','Configuration','PreGame'))} />
          </div>

          <div className="cell cell3hmerge">
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('CountryProfile','Configuration','Instruction'))} rounded raised />
          </div> 
      
      </div>
    </div>
  )
}
