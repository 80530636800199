import { useState,useEffect,useRef } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { InputText } from 'primereact/inputtext';

import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';

export const Timer = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    showCultivatedYield,
    setShowCultivatedYield,
    showPlayers,
    setShowPlayers,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowStrategyResults,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    StrategyCards
  } = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  const [seconds, setSeconds] = useState(0);
  const [timer, startTimer] = useState(false);
  
  const [minute, setMinute] = useState<number>(0);
  const toast = useRef<Toast | null>(null);
  const inter = useRef(null);

  useEffect(() => {
    setSeconds(600);
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if(timer){
      let _val = 0;
      inter.current = setInterval(() => {          
          _val += 0.3334;          
          if (_val >= 100) {
              _val = 100;
              toast.current.show({ severity: 'error', summary: 'Time Up', detail: 'Time is Up!' });
              clearInterval(inter.current);
          }
          setMinute(_val);          
      }, 2000);
      return () => {
          if (inter.current) {
              clearInterval(inter.current);
              inter.current = null;
          }
      };
    }
    return () => clearInterval(interval);
  }, [timer]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    let displayTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    if(minutes < 0)
      displayTime = "Time Is Up!";
    return displayTime;
  };

  const [PM1, setPM1] = useState(null);
  const [PM2, setPM2] = useState(null);
  const [PM3, setPM3] = useState(null);
  const [PM4, setPM4] = useState(null);
  const [PM5, setPM5] = useState(null);
  const [CL1, setCL1] = useState(null);
  const [CL2, setCL2] = useState(null);
  const [CL3, setCL3] = useState(null);
  const [CL4, setCL4] = useState(null);
  const [CL5, setCL5] = useState(null);
  const [F1, setF1] = useState(null);
  const [F2, setF2] = useState(null);
  const [F3, setF3] = useState(null);
  const [F4, setF4] = useState(null);
  const [F5, setF5] = useState(null);
  const defaultValues = {
      PM1: PM1,
      PM2: PM2,
      PM3: PM3,
      PM4: PM4,
      PM5: PM5,
      CL1: CL1,
      CL2: CL2,
      CL3: CL3,
      CL4: CL4,
      CL5: CL5,
      F1: F1,
      F2: F2,
      F3: F3,
      F4: F4,
      F5: F5
  };
  const {
      control,
      formState: { errors },
      handleSubmit,
      getValues,
      setValue,
      reset
  } = useForm({ defaultValues });
  const onSubmit = (data: any) => {
      setPolicyMakerStrategy({...policyMakerStrategy, 1: data.PM1, 2: data.PM2, 3: data.PM3, 4: data.PM4, 5: data.PM5, spend: policyMakerStrategy.budget+benefits.coins-tempPlayerBalance['Policy Maker'] });
      setCommunityLeaderStrategy({...communityLeaderStrategy, 1: data.CL1, 2: data.CL2, 3: data.CL3, 4: data.CL4, 5: data.CL5, spend: communityLeaderStrategy.budget+benefits.coins-tempPlayerBalance['Community Leader'] });
      setFarmerStrategy({...farmerStrategy, 1: data.F1, 2: data.F2, 3: data.F3, 4: data.F4, 5: data.F5, spend: farmerStrategy.budget+benefits.coins-tempPlayerBalance['Farmer'] });
      setShowTimer(false);
      setShowStrategyResults(true);
      reset();
  };
  const getFormErrorMessage = (name:any) => {
      return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  let playerBalance = useRef({
    "Policy Maker":policyMakerStrategy.budget+benefits.coins,
    "Community Leader":communityLeaderStrategy.budget+benefits.coins,
    "Farmer":farmerStrategy.budget+benefits.coins
  });
  const [overBudget,setOverBudget] = useState(false);
  const [tempPlayerBalance,setTempPlayerBalance] = useState(playerBalance.current);
  const calculateBalance = (target)=>{
    let player = target.substring(0,target.length-1);
    let Player = "";
    if(player === "PM")
      Player = "Policy Maker";
    else if(player === "CL")
      Player = "Community Leader";
    else if(player === "F")
      Player = "Farmer";

    let balance = playerBalance.current[Player];
    for (let i = 1; i <= 5; i++) {
      let strategy = (document.getElementById(player+i) as HTMLInputElement).value;
      if(strategy !== ""){
        let duplicateError = false;
        if(StrategyCards?.current[Player][strategy] !== undefined){
          for (let i2 = 1; i2 <= 5; i2++) {
            if(i2 !== i){
              let strategyToCheck = (document.getElementById(player+i2) as HTMLInputElement).value;
              if(strategy === strategyToCheck){
                alert("Duplicate Strategy Card!");
                setValue(target,"");
                eval('set'+target+'(null)');
                duplicateError = true;
              }
            }
          }
          if(!duplicateError){
            balance = balance - StrategyCards?.current[Player][strategy].price;
            if(balance < 0){
              alert(Player+" is over budget!");
              setValue(target,"");
              eval('set'+target+'(null)');              
              setOverBudget(true);
            }
            else
              setOverBudget(false);
          }
        }
        else{
          alert("Strategy Card does not exist!");
          setValue(target,"");
          eval('set'+target+'(null)');          
        }
      }
    }
    setTempPlayerBalance({...tempPlayerBalance, [Player]: balance});
  }

  const [disableTimer,setTimer] = useState(false);

  return (    
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell flex-column">
          <img src={'images/'+CountryProfiles.current[selectedCountry]['image']+'.png'} alt={selectedCountry} style={{ maxHeight: '75px', maxWidth: '90%'}} />
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center', color: '#555' }}>{selectedCountry}</p>
          <span style={{ fontSize: '2vw',fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Pick your strategies</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
        </div>
      </div>

      <div className="content">
           
          <div className="cell cell3vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Timer','Players'))} />
          </div>         
          <div className="cell cell3vmerge cell2hmerge flex-column">
            <div className="flex flex-row grid-nogutter" style={{ width: '100%' }}>

              <div className="flex flex-column align-items-center justify-content-center col-3">
                <div className="flex flex-column align-items-center gap-4 p-0 m-0 pb-4" style={{ height: 'fit-content', width: '70%', backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px' }}>                  
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-0" style={{ width:'50%' }}>
                      <img src='images/floods2.png' alt='Flood Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Flood Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.25vw', fontWeight: 'bold' }}>{CountryProfiles.current[selectedCountry][round][selectedLevel]['flood']}%</h1>
                    </div>
                  </div>
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-0" style={{ width:'50%' }}>
                      <img src='images/drought2.png' alt='Drought Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Drought Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.25vw', fontWeight: 'bold' }}>{CountryProfiles.current[selectedCountry][round][selectedLevel]['drought']}%</h1>
                    </div>
                  </div>
                  <div className="block flex">
                    <div className="inline-block flex align-items-end justify-content-center p-0 m-0" style={{ width:'50%' }}>
                      <img src='images/wildfire2.png' alt='Wildfire Risk' style={{ borderRadius: '50%', width: '60%' }} />
                    </div>
                    <div className="inline-block flex flex-column align-items-start justify-content-center p-0 m-0" style={{ width:'50%' }}>
                        <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Wildfire Risk</p>
                        <h1 className="p-0 m-0" style={{ fontSize: '1.25vw', fontWeight: 'bold' }}>{CountryProfiles.current[selectedCountry][round][selectedLevel]['wildfire']}%</h1>
                    </div>
                  </div>
                </div>

              </div>

              <div className="flex flex-column align-items-center justify-content-center col-9">
                
                {!timer && 
                <>
                  <p className="p-0 m-0 mb-5" style={{fontSize: '2vw', fontWeight: '400' }}>Action!</p>
                  <p className="p-0 m-0 mb-5" style={{fontSize: '1.5vw', fontWeight: '400' }}>Policy Makers, Community Leaders and Farmers it is time to gather and pick 5 strategy cards.</p>
                  <p className="p-0 m-0 mb-5" style={{fontSize: '2vw', fontWeight: '400' }}>You have 10 Minutes!</p>
                </>}
                {timer && 
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-row align-items-center justify-content-center gap-5">
                      <div className="flex flex-column align-items-center justify-content-center" style={{ height: '100%', background: '#FFE6A5', borderRadius: '20px', padding: 20 }}>
                        <div className="flex align-items-center justify-content-center" style={{ width: '13vw', background: '#FFFCF3', borderRadius: '20px' }}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 10}} /></div>
                        <p className="p-0 m-0" style={{fontSize: '1.5vw', fontWeight: '900' }}>Policy Maker</p>
                        <div className="flex flex-row mt-1"><p className="p-0 m-0" style={{fontSize: '1.25vw' }}><i className='fa fa-coins' />{tempPlayerBalance['Policy Maker']}</p></div>
                        <Controller
                            name="PM1"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 1:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM1(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="PM2"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 2:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM2(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="PM3"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 3:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM3(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="PM4"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 4:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM4(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="PM5"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 5:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM5(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />
                                </div>
                            )}
                        />
                      </div>
                      <div className="flex flex-column align-items-center justify-content-center" style={{ height: '100%', background: '#FFE6A5', borderRadius: '20px', padding: 20 }}>
                        <div className="flex align-items-center justify-content-center" style={{ width: '13vw', background: '#FFFCF3', borderRadius: '20px' }}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 10}} /></div>
                        <p className="p-0 m-0" style={{fontSize: '1.5vw', fontWeight: '900' }}>Community Leader</p>
                        <div className="flex flex-row mt-1"><p className="p-0 m-0" style={{fontSize: '1.25vw' }}><i className='fa fa-coins' />{tempPlayerBalance['Community Leader']}</p></div>
                        <Controller
                            name="CL1"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 1:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL1(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="CL2"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 2:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL2(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="CL3"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 3:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL3(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="CL4"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 4:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL4(e.target.value);calculateBalance(e.target.name);}}  keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="CL5"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 5:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL5(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                      </div>
                      <div className="flex flex-column align-items-center justify-content-center" style={{ height: '100%', background: '#FFE6A5', borderRadius: '20px', padding: 20 }}>
                        <div className="flex align-items-center justify-content-center" style={{width: '13vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmers' style={{maxWidth: '100%', padding: 10}} /></div>
                        <p className="p-0 m-0" style={{fontSize: '1.5vw', fontWeight: '900' }}>Farmer</p>
                        <div className="flex flex-row mt-1"><p className="p-0 m-0" style={{fontSize: '1.25vw', fontWeight: '900' }}><i className='fa fa-coins' />{tempPlayerBalance['Farmer']}</p></div>
                        <Controller
                            name="F1"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 1:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setF1(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="F2"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 2:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setF2(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="F3"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 3:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setF3(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="F4"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 4:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setF4(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                        <Controller
                            name="F5"
                            control={control}
                            render={({ field, fieldState }) => (
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw' }}>Card 5:</span>
                                    <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setF5(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} max={20} required />                              
                                </div>
                            )}
                        />
                      </div>         
                    </div>
                    <div className="flex flex-row mt-1 align-items-center justify-content-center" >
                      <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="Submit Strategies" icon="fa-solid fa-hourglass-end" style={{ width: '60%', alignSelf: 'center', verticalAlign: 'center', fontSize: 'small', height: 30 }} rounded raised disabled={overBudget} />
                    </div>                
                  </form>              
                  </>
                }

                <div className="flex align-items-center justify-content-center mt-1" style={{ height:'fit-content', width: '50%', background: '#FEE29B', borderRadius: '25px' }}> 
                    {!timer && 
                      <div className="flex flex-column align-items-center justify-content-center">
                        <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>Ready, set, go!</p>
                        <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="Start the Clock" icon="fa-solid fa-hourglass-start" onClick={()=>{startTimer(true);setTimer(true);}} disabled={disableTimer} rounded raised />
                      </div>
                    }
                    {timer && 
                      <>                        
                        <div className="flex flex-column align-items-center justify-content-center z-1" style={{ width: '100%'}}>
                          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}>Time remaining:<br/>{formatTime(seconds)}</p>                          
                          <div style={{ width: '80%', padding: 1 }}>
                            <Toast ref={toast}></Toast>
                            <video src="./video/grow.mp4" width="100%" height="75" autoPlay={true} loop={true} />
                            {/*<ProgressBar value={Math.floor(minute)} color="green"></ProgressBar>*/}
                          </div>
                        </div>
                      </>
                    }
                </div>
                
              </div>
            </div>            
            
          </div>
      
      </div>
    </div>
  )
}
