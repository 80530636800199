import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const Players = (props) => {
  const {showComponent,hideComponent,showPlayers,setShowPlayers,setShowGoals} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }  

  return (    
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Three teams, one mission</span>
        </div>
        <div className="cell">
          &nbsp;
        </div>
      </div>

      <div className="content" style={{ overflow: 'hidden' }}>

          <div className="cell cell3hmerge">
            Let's create the teams and fight the climate change together. Choose your character!
          </div>          
          <div className="cell cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Players','Mission'))} />
          </div>
          <div className="cell flex-column">
            <div className="flex flex-row align-items-center justify-content-center gap-3">
              <div className="flex flex-column align-items-center justify-content-center" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '20vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Policy Maker</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw' }}>Policymakers shape farming policies but struggle with direct farm enforcement and customization due to practical constraints.</p>
                <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="50 coins" icon="fa-solid fa-coins" rounded raised />
              </div>
              <div className="flex flex-column align-items-center justify-content-center" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '20vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Community Leader</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw' }}>Community leaders execute local farming policies through workshops, resource management, and farm demonstrations.</p>
                <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="30 coins" icon="fa-solid fa-coins" iconPos="bottom" rounded raised />
              </div>
              <div className="flex flex-column align-items-center justify-content-center" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10 }}>
                <div className="flex align-items-center justify-content-center" style={{width: '20vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmers' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Farmers</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw' }}>Farmers influence their approach but lack policy-making authority and face climate-related yield impacts.</p>
                <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="20 coins" icon="fa-solid fa-coins" iconPos="right" rounded raised />
              </div>
            </div>
          </div>
          <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Players','Goals'))} />
          </div>
          <div className="cell cell3hmerge">
            &nbsp;
          </div> 
      
      </div>
    </div>
  )
}
