import { Levels } from './Levels';
import { Countries } from './Countries';
import { CountryProfile } from './CountryProfile';
import { Rounds } from './Rounds';

export const Configuration = (props) => {
  const {
    showComponent,
    hideComponent,
    round,
    showLevels,
    setShowLevels,
    showRounds,
    setShowRounds,
    showPlayers,
    setShowPlayers,
    showCountries,
    setShowCountries,
    selectedLevel,
    setSelectedLevel,
    selectedPlayer,    
    setSelectedPlayer,    
    setSelectedCountry,
    setShowInstruction,
    setShowLanding,
    setShowConfiguration,
    selectedCountry,
    showCountryProfile,
    setShowCountryProfile,
    CountryProfiles,
    setShowPreGame,
    setShowStrategy,
    resetCountry,
    setResetCountry
  } = props;
  setShowLanding(false);
  setShowInstruction(false);
  //setShowConfiguration(false); 
  setShowPreGame(false);
  setShowStrategy(false);
  if(round <= 1){
    if(showLevels === false && showRounds === false && showCountries === false && showCountryProfile === false){    
        setShowLevels(true);
    }
  }
  else{
    if(showLevels === false && showRounds === false && showCountries === false && showCountryProfile === false){    
      setShowRounds(true);
    }
  }
  return (
    <>
      {showLevels && <Levels 
                      showComponent={showComponent} 
                      hideComponent={hideComponent} 
                      setShowInstruction={setShowInstruction}
                      round={round}
                      showLevels={showLevels} 
                      setShowLevels={setShowLevels}
                      showRounds={showRounds} 
                      setShowRounds={setShowRounds}
                      showCountries={showCountries}
                      setShowCountries={setShowCountries} 
                      selectedLevel={selectedLevel}
                      setSelectedLevel={setSelectedLevel}                      
                      selectedCountry={selectedCountry}                      
                      />}
      {showRounds && <Rounds
                      showComponent={showComponent} 
                      hideComponent={hideComponent} 
                      setShowInstruction={setShowInstruction}
                      round={round}
                      showLevels={showLevels} 
                      setShowLevels={setShowLevels}
                      showRounds={showRounds} 
                      setShowRounds={setShowRounds}
                      showCountries={showCountries}
                      setShowCountries={setShowCountries} 
                      selectedLevel={selectedLevel}
                      setSelectedLevel={setSelectedLevel}                      
                      selectedCountry={selectedCountry}
                      resetCountry={resetCountry}
                      showCountryProfile={showCountryProfile}
                      setShowCountryProfile={setShowCountryProfile}
                      />}
      {showCountries && <Countries 
                          showComponent={showComponent} 
                          hideComponent={hideComponent} 
                          setShowInstruction={setShowInstruction}
                          round={round}
                          showLevels={showLevels} 
                          setShowLevels={setShowLevels}                           
                          showCountries={showCountries} 
                          setShowCountries={setShowCountries} 
                          selectedLevel={selectedLevel}
                          setSelectedLevel={setSelectedLevel}                          
                          setSelectedCountry={setSelectedCountry}
                          selectedCountry={selectedCountry}
                          resetCountry={resetCountry}
                          setResetCountry={setResetCountry}
                          />}
      {showCountryProfile && <CountryProfile
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration}
                              setShowCountryProfile={setShowCountryProfile}
                              round={round}
                              selectedLevel={selectedLevel}                              
                              selectedCountry={selectedCountry}
                              CountryProfiles={CountryProfiles}
                              resetCountry={resetCountry}
                              setResetCountry={setResetCountry}                           
                              />}
    </>
  )
}
