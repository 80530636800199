import { useRef,useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const CropMatch = (props) => {
  const {showComponent,hideComponent,setShowInstruction,setShowConfiguration,round,selectedLevel,selectedCountry,selectedCrop,crops,setShowPickCrop,setShowLevel,CountryProfiles,CropProfiles,benefits,setBenefits,resetCrop} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  const Comparator = (reference,value)=>{
    if(typeof(reference) === "object"){
      if(reference.low <= value && value <= reference.high)
        return true;
      else
        return false;
    }
    else if(typeof(reference) === "number"){
      if(reference <= value)
        return true;
      else
        return false;
    }
    else if(typeof(reference) === "string"){
      if(reference === "Low")
        reference = 1;
      else if(reference === "Medium")
        reference = 2;
      else if(reference === "High")
        reference = 3;
      else{
        if(reference === value)
          return true;
        else
          return false;
      }
      if(value === "Low")
        value = 1;
      else if(value === "Medium")
        value = 2;
      else if(value === "High")
        value = 3;
      if(reference <= value)
        return true;
      else
        return false;
    }
    else
      return false;  
  }

  const goodChoice = useRef<any>({});  
  const CalculateScore = (country,crop)=>{
    let score = 0;    
    Object.entries(CropProfiles.current[crop]).forEach((CropElement)=>{      
      let value = CountryProfiles.current[country][round][selectedLevel][CropElement[0]];      
      let reference = CropElement[1];      
      if(value !== undefined){        
        if(Comparator(reference,value)){          
          score++;          
          goodChoice.current = {...goodChoice.current, [crop.charAt(0).toUpperCase()+crop.slice(1)]:{...goodChoice.current[crop.charAt(0).toUpperCase()+crop.slice(1)], [CropElement[0].charAt(0).toUpperCase()+CropElement[0].slice(1)]:CountryProfiles.current[country][round][selectedLevel][CropElement[0]]}};          
        }
      }
    });
    return score;
  }

  let display = '';
  let cropNames = [];
  let cropScore = {};
  let totalScore = 0;
  Object.entries(selectedCrop).map(crop=>{      
    if(crop[1] === true){
      let cropname = crop[0].charAt(0).toUpperCase()+crop[0].slice(1);
      cropNames.push(cropname);      
      display = display+cropname+' ';
      let tempScore = CalculateScore(selectedCountry,crop[0]);      
      cropScore = {...cropScore, [crop[0]]: tempScore};
      totalScore = totalScore+tempScore;
    }
  });
  crops.current['display'] = display;
  crops.current['cropNames'] = cropNames.sort();
  crops.current['totalScore'] = totalScore;

  let threshold = 2;
  let coinBenefit = 0;
  if(totalScore >= threshold){
    crops.current['pass'] = true;
    coinBenefit = 15;  
  }
  else
    crops.current['pass'] = false;
  useEffect(()=>{
    setBenefits({...benefits, coins: coinBenefit});
  },[coinBenefit]);

  const displayGoodChoice = useRef<any>("");
  let tempDisplayGoodChoiceGroup = "";
  Object.entries(goodChoice.current).forEach((goodChoice)=>{
    let tempDisplayGoodChoice = "";  
    Object.entries(goodChoice[1]).forEach((parameters)=>{
      tempDisplayGoodChoice = tempDisplayGoodChoice+" "+parameters[0].replace(/_/gi," ")+" ("+parameters[1]+")";
    });
    tempDisplayGoodChoiceGroup = tempDisplayGoodChoiceGroup+"<br/>"+goodChoice[0]+":"+ tempDisplayGoodChoice;
  });
  displayGoodChoice.current = tempDisplayGoodChoiceGroup;

  return (
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Crop Matching Criteria</span>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
        </div>
      </div>

      <div className="content" style={{ overflow: 'hidden' }}>

        <div className="cell cell3hmerge">          
          {crops.current['pass'] && 
            <>
              <img className="flex" src="images/congratulations.png" alt="Congratulations" style={{ maxHeight: '90%' }} />
              <img className="flex" src="images/goodselectcrop.png" alt="Selection Result" style={{ maxHeight: '90%' }} />
            </>
          }
        </div>
        <div className="cell">
        {!(round > 1 && !resetCrop) && <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('CropMatch','PickCrop'))} />}
        </div>
        <div className="cell flex-column align-items-center justify-content-center">
          <div className="flex flex-row gap-4">
            <div className="flex flex-column align-items-center justify-content-center" style={{ height: 'fit-content', width: 'fit-content' }}>
              <img src={'images/'+crops.current['cropNames'][0].toLowerCase()+'.png'} alt={crops.current['cropNames'][0]} style={{maxHeight: '100px'}} />
              <h1 className="p-0 m-0" style={{ color: '#235F46', fontSize: '1.5vw', fontWeight: 'bold' }}>{crops.current['cropNames'][0]}: {cropScore[crops.current['cropNames'][0].toLowerCase()]}</h1>
            </div>
            <div className="flex flex-column align-items-center justify-content-center" style={{ height: 'fit-content', width: 'fit-content' }}>            
              <img src={'images/'+crops.current['cropNames'][1].toLowerCase()+'.png'} alt={crops.current['cropNames'][1]} style={{maxHeight: '100px'}} />              
              <h1 className="p-0 m-0" style={{ color: '#235F46', fontSize: '1.5vw', fontWeight: 'bold' }}>{crops.current['cropNames'][1]}: {cropScore[crops.current['cropNames'][1].toLowerCase()]}</h1>
            </div>
          </div>
          {crops.current['pass'] &&
            <div className="flex flex-column p-0 m-0" style={{ height: 'fit-content' }}>
              <div className="flex flex-column align-items-center justify-content-center gap-2 mt-3 pb-3" style={{ backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px', width: '400px', height: 'fit-content' }}>
                <h1 className="p-0 m-0" style={{ color: '#235F46', fontSize: '1.25vw', fontWeight: 'bold', textAlign: 'center' }}>Matching Criteria</h1>
                <p className="p-0 m-0" style={{ color: '#235F46', fontSize: '1vw', textAlign: 'center' }}  dangerouslySetInnerHTML={{__html: displayGoodChoice.current}} />                
              </div>
              <div className="flex relative z-1 align-items-center justify-content-center p-3 m-0" style={{ left: '-150px', bottom: '200px', transform: "rotate(-15deg)", backgroundColor: '#235F46', borderRadius: '50%', width: '170px', height: '170px' }}>
                <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.15vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-smile-beam" style={{ fontSize: '2.5rem' }}></i><br/>Good job! each player gets 15 coins</p>
              </div>              
            </div>
          }
          {!crops.current['pass'] &&
            <div className="flex align-items-center justify-content-center p-3 m-0" style={{ bottom: '200px', transform: "rotate(15deg)", backgroundColor: 'red', borderRadius: '50%', width: '300px', height: '300px' }}>
              <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.5vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-sad-tear" style={{ fontSize: '3rem' }}></i><br/>Poor selection of crops. No additional benefit to the players.</p>
            </div>
          }       
        </div>
        <div className="cell">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('CropMatch','CultivatedYield'))} />
        </div>

        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('CropMatch','PreGame','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('CropMatch','PreGame','Configuration'))} rounded raised />
        </div> 
      
      </div>
    </div>
  )
}
