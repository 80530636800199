import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,calculateScore,returnVisuals } from '../../../App';

export const CompleteScore = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,    
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let threshold = {upper: 80,lower: 45};

  const retrieveScore = (player)=>{    
    let economic_score = 0;
    let environmental_score = 0;
    let social_score = 0;
    let entry = '';
    if(player === 'Policy Maker')
      entry = policyMakerStrategy;
    else if(player === 'Community Leader')
      entry = communityLeaderStrategy;
    else if(player === 'Farmer')
      entry = farmerStrategy;
    Object.entries(entry).forEach((selectedCard)=>{      
      if(selectedCard[0] !== "spend"){
        if(StrategyCards?.current[player][selectedCard[1]] !== undefined){
          economic_score = economic_score+StrategyCards?.current[player][selectedCard[1]]['economic_score'];
          environmental_score = environmental_score+StrategyCards?.current[player][selectedCard[1]]['environmental_score'];
          social_score = social_score+StrategyCards?.current[player][selectedCard[1]]['social_score'];
        }        
      }
    });
    economic_score = Math.ceil(economic_score/5);
    environmental_score = Math.ceil(environmental_score/5);
    social_score = Math.ceil(social_score/5);
    
    let coinBenefit = 15;
    let coinLoss = -5;

    let economic_result = calculateScore(economic_score,threshold);
    let economic_benefit = 0;
    let economic_loss = 0;
    if(economic_result === "Good")
      economic_benefit = economic_benefit+coinBenefit;
    else if(economic_result === "Poor")
      economic_loss = economic_loss+coinLoss;
    
    let environmental_result = calculateScore(environmental_score,threshold);
    let environmental_benefit = 0;
    let environmental_loss = 0;
    if(environmental_result === "Good")
      environmental_benefit = environmental_benefit+coinBenefit;
    else if(environmental_result === "Poor")
      environmental_loss = environmental_loss+coinLoss;

    let social_result = calculateScore(social_score,threshold);
    let social_benefit = 0;
    let social_loss = 0;
    if(social_result === "Good")
      social_benefit = social_benefit+coinBenefit;
    else if(social_result === "Poor")
      social_loss = social_loss+coinLoss;
    
    return({ economic_score: economic_score, economic_result: economic_result, economic_benefit: economic_benefit, economic_loss: economic_loss,
             environmental_score: environmental_score, environmental_result: environmental_result, environmental_benefit: environmental_benefit, environmental_loss: environmental_loss,
             social_score: social_score, social_result: social_result, social_benefit: social_benefit, social_loss: social_loss });
  }

  let policyMakerScores = retrieveScore('Policy Maker');
  let communityLeaderScores = retrieveScore('Community Leader');
  let farmerScores = retrieveScore('Farmer');

  const [triggerBenefits,setTriggerBenefits] = useState(true);
  useEffect(()=>{
    if(triggerBenefits){
      let tempBenefits = {
        "Policy Maker": {cooperation: benefits['Policy Maker'].cooperation, economic: policyMakerScores.economic_benefit+policyMakerScores.economic_loss, environmental: policyMakerScores.environmental_benefit+policyMakerScores.environmental_loss, social: policyMakerScores.social_benefit+policyMakerScores.social_loss},
        "Community Leader": {cooperation: benefits['Community Leader'].cooperation, economic: communityLeaderScores.economic_benefit+communityLeaderScores.economic_loss, environmental: communityLeaderScores.environmental_benefit+communityLeaderScores.environmental_loss, social: communityLeaderScores.social_benefit+communityLeaderScores.social_loss},
        "Farmer": {cooperation: benefits['Farmer'].cooperation, economic: farmerScores.economic_benefit+farmerScores.economic_loss, environmental: farmerScores.environmental_benefit+farmerScores.environmental_loss, social: farmerScores.social_benefit+farmerScores.social_loss}
      }
      setBenefits({...benefits, ...tempBenefits});
      let tempScores = {
        "Policy Maker": {economic_score: policyMakerScores.economic_score, environmental_score: policyMakerScores.environmental_score, social_score: policyMakerScores.social_score, climate_adaptation_score: scores['Policy Maker'].climate_adaptation_score},
        "Community Leader": {economic_score: communityLeaderScores.economic_score, environmental_score: communityLeaderScores.environmental_score, social_score: communityLeaderScores.social_score, climate_adaptation_score: scores['Community Leader'].climate_adaptation_score},
        "Farmer": {economic_score: farmerScores.economic_score, environmental_score: farmerScores.environmental_score, social_score: farmerScores.social_score, climate_adaptation_score: scores['Farmer'].climate_adaptation_score}
      }
      setScores({...scores, ...tempScores});
      setTriggerBenefits(false);
    }
  },[policyMakerScores,communityLeaderScores,farmerScores]);
  
  let overall = calculateScore((((policyMakerScores.economic_score+policyMakerScores.environmental_score+policyMakerScores.social_score)/3)+((communityLeaderScores.economic_score+communityLeaderScores.environmental_score+communityLeaderScores.social_score)/3)+((farmerScores.economic_score+farmerScores.environmental_score+farmerScores.social_score)/3))/3,threshold);  
  let visuals = {"Policy Maker": {economic: returnVisuals(policyMakerScores.economic_result), environmental: returnVisuals(policyMakerScores.environmental_result), social: returnVisuals(policyMakerScores.social_result)},
                 "Community Leader": {economic: returnVisuals(communityLeaderScores.economic_result), environmental: returnVisuals(communityLeaderScores.environmental_result), social: returnVisuals(communityLeaderScores.social_result)},
                 "Farmer": {economic: returnVisuals(farmerScores.economic_result), environmental: returnVisuals(farmerScores.environmental_result), social: returnVisuals(farmerScores.social_result)},
                 "Overall": returnVisuals(overall)};

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social;

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '2.3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Economic, Environmental and Social Score</span>
          <p className="p-0 m-0" style={{ fontSize: '0.75vw', textAlign: 'center' }}>This score measures your success in securing the region's future through smart planning and strategic investments across economic, environmental, and social aspects</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('CompleteScore','Evaluation'))} />
        </div>

        <div className="cell cell2vmerge flex-column">          
            
          <div className="flex flex-row align-items-start justify-content-center p-0 m-0" style={{ height: 'fit-content', width: 'fit-content' }}>
            <div className="flex flex-column" style={{width: '20vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', height: 'fit-content', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Policy Maker</p>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Environmental Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Policy Maker'].environmental.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{policyMakerScores.environmental_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Economic Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Policy Maker'].economic.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{policyMakerScores.economic_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Social Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Policy Maker'].social.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{policyMakerScores.social_score}</p>
                </div>
              </div>
            </div>
           <div className="flex flex-column" style={{width: '20vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Community Leader</p>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Environmental Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Community Leader'].environmental.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{communityLeaderScores.environmental_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Economic Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Community Leader'].economic.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{communityLeaderScores.economic_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Social Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Community Leader'].social.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{communityLeaderScores.social_score}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-column" style={{width: '20vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
              <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmer' style={{maxWidth: '100%', padding: 5}} /></div>
              <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', textAlign: 'center', fontWeight: '900' }}>Farmer</p>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Environmental Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Farmer'].environmental.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{farmerScores.environmental_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Economic Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Farmer'].economic.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{farmerScores.economic_score}</p>
                </div>
              </div>
              <div className="flex flex-row align-items-center m-2">
                <div style={{width: '90%'}}>
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>Social Score</p>
                </div>
                <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '2.5vw', height: '2.5vw', borderRadius: '50%', background: visuals['Farmer'].social.color, color: 'white', textAlign: 'center' }} >
                  <p className="p-0 m-0" style={{ fontSize: '1.25vw' }}>{farmerScores.social_score}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-column align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-2" style={{ height: '60px', maxHeight: '25%', width: '70%', borderRadius: '20px' }}>
            <p className="p-0 m-0" style={{ fontSize: '1.5vw', color: 'white', textShadow: '2px 2px 4px #000' }}>Congratulations, the collective efforts of all players have led to a</p>
            <div className="flex flex-row align-items-center">
              <div className="flex flex-wrap align-items-center justify-content-center p-0 m-0" style={{ width: '40px', height: '40px', borderRadius: '50%', background: visuals['Overall'].color, color: 'white', textAlign: 'center' }} >
                <i className={visuals['Overall'].icon}  style={{ fontSize: '1.5rem' }} />
              </div>
              <span style={{ fontSize: '2vw', fontWeight: 'bolder', color: 'white', textShadow: '2px 2px 4px #000' }}> {overall} Score</span>
            </div>
            {((policyMakerScores.economic_benefit > 0 || policyMakerScores.environmental_benefit > 0 || policyMakerScores.social_benefit > 0) || (communityLeaderScores.economic_benefit > 0 || communityLeaderScores.environmental_benefit > 0 || communityLeaderScores.social_benefit > 0) || (farmerScores.economic_benefit > 0 || farmerScores.environmental_benefit > 0 || farmerScores.social_benefit > 0)) && 
              <>
                <div className="z-1 absolute flex flex-column align-items-center justify-content-center gap-2 p-4 mb-2" style={{ left: '20px', top: '150px', transform: "rotate(-5deg)", backgroundImage: 'linear-gradient(rgba(56,186,139,0), rgba(56,186,139,1))', borderRadius: '25px', width: '270px' }}>                  
                  {(policyMakerScores.economic_benefit > 0 || communityLeaderScores.economic_benefit > 0 || farmerScores.economic_benefit > 0) &&                
                   <p className="p-0 m-0" style={{ fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>For an outstanding economic score, the {policyMakerScores.economic_benefit > 0? 'Policy Maker ': ''}{communityLeaderScores.economic_benefit > 0? 'Community Leader ': ''}{farmerScores.economic_benefit > 0? 'Farmer ': ''} receive rewards!</p>
                  }
                  {(policyMakerScores.environmental_benefit > 0 || communityLeaderScores.environmental_benefit > 0 || farmerScores.environmental_benefit > 0) &&                
                   <p className="p-0 m-0" style={{ fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>For environmentally conscious score, the {policyMakerScores.environmental_benefit > 0? 'Policy Maker ': ''}{communityLeaderScores.environmental_benefit > 0? 'Community Leader ': ''}{farmerScores.environmental_benefit > 0? 'Farmer ': ''} receive rewards!</p>
                  }
                  {(policyMakerScores.social_benefit > 0 || communityLeaderScores.social_benefit > 0 || farmerScores.social_benefit > 0) &&                
                   <p className="p-0 m-0" style={{ fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>For socially adept scoring, the {policyMakerScores.social_benefit > 0? 'Policy Maker ': ''}{communityLeaderScores.social_benefit > 0? 'Community Leader ': ''}{farmerScores.social_benefit > 0? 'Farmer ': ''} receive rewards!</p>
                  }
                </div>
                <div className="z-2 absolute flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ left: '270px', top: '130px', transform: "rotate(15deg)", backgroundColor: '#235F46', borderRadius: '50%', width: '6.5vw', height: '6.5vw' }}>
                  <p className="p-0 m-0" style={{  color: '#fff', fontSize: '0.75vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-smile-beam" style={{ fontSize: '1.5rem' }}></i><br/>The player gets an additional 15 coins</p>
                </div>
              </>
            }
            {((policyMakerScores.economic_loss < 0 || policyMakerScores.environmental_loss < 0 || policyMakerScores.social_loss < 0) || (communityLeaderScores.economic_loss < 0 || communityLeaderScores.environmental_loss < 0 || communityLeaderScores.social_loss < 0) || (farmerScores.economic_loss < 0 || farmerScores.environmental_loss < 0 || farmerScores.social_loss < 0)) && 
              <>
                <div className="z-1 absolute flex flex-column align-items-center justify-content-center gap-2 p-4 mb-2" style={{ right: '50px', top: '170px', transform: "rotate(-5deg)", backgroundImage: 'linear-gradient(rgba(255,25,25,1), rgba(255,25,25,0.5))', borderRadius: '25px', width: '270px' }}>                  
                  {(policyMakerScores.economic_loss < 0 || communityLeaderScores.economic_loss < 0 || farmerScores.economic_loss < 0) &&                
                   <p className="p-0 m-0" style={{ color: '#FFFCF3', fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>Tough economic times have hit the {policyMakerScores.economic_loss < 0? 'Policy Maker ': ''}{communityLeaderScores.economic_loss < 0? 'Community Leader ': ''}{farmerScores.economic_loss < 0? 'Farmer ': ''}, coins are taken away from your hard-earned savings!!</p>
                  }
                  {(policyMakerScores.environmental_loss < 0 || communityLeaderScores.environmental_loss < 0 || farmerScores.environmental_loss < 0) &&                
                   <p className="p-0 m-0" style={{ color: '#FFFCF3', fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>Environmentally unfriendly score leads to {policyMakerScores.environmental_loss < 0? 'Policy Maker ': ''}{communityLeaderScores.environmental_loss < 0? 'Community Leader ': ''}{farmerScores.environmental_loss < 0? 'Farmer ': ''} coins being taken away!!</p>
                  }
                  {(policyMakerScores.social_loss < 0 || communityLeaderScores.social_loss < 0 || farmerScores.social_loss < 0) &&                
                   <p className="p-0 m-0" style={{ color: '#FFFCF3', fontSize: '0.75vw', textAlign: 'center', fontWeight: '900px' }}>Social suffering {policyMakerScores.social_loss < 0? 'Policy Maker ': ''}{communityLeaderScores.social_loss < 0? 'Community Leader ': ''}{farmerScores.social_loss < 0? 'Farmer ': ''} losing coins!!</p>
                  }
                </div>
                <div className="z-2 absolute flex flex-wrap align-items-center justify-content-center p-3 m-0" style={{ right: '290px', top: '140px', transform: "rotate(-15deg)", backgroundColor: 'red', borderRadius: '50%', width: '6vw', height: '6vw' }}>
                  <p className="p-0 m-0" style={{ color: '#fff', fontSize: '0.75vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-regular fa-face-sad-tear" style={{ fontSize: '1.5rem' }}></i><br/>The player loses 5 coins</p>
                </div>
              </>
            }
          </div>
        </div>
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('CompleteScore','DisasterSelection'))} />
        </div>

        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('CompleteScore','Strategy','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('CompleteScore','Strategy','Configuration'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Strategies" onClick={()=>(processClick('CompleteScore','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
