import { useState,useRef,useEffect } from "react";

import { Landing } from "./components/Landing";
import { Instruction } from "./components/Instruction";
import { Configuration } from "./components/config/Configuration";
import { PreGame } from "./components/GamePlay/PreGame/PreGame";
import { Strategy } from "./components/GamePlay/Strategy/Strategy";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';
import 'fontawesome/css/all.css';

import './css/App.css';

export const number2word = (number: number)=> {
  if (number < 0)
    return false;
  else if (number === 0) 
    return 'Zero';

  let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  let double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];  
  
  function translate(n) {
    let word = "";    
    if (n < 10)
      word = single_digit[n] + ' ';      
    else if (n < 20)
      word = double_digit[n - 10] + ' ';    
    return word;
  }
  let result = translate(number);
  return result.trim();
}

export const calculateScore = (sumOfScore,threshold)=>{
    if(sumOfScore >= threshold.upper)
      return('Good');
    else if(sumOfScore < threshold.upper && sumOfScore >= threshold.lower)
      return('Fair');
    else
      return('Poor');
}
export const returnVisuals = (score)=>{
  if(score === "Good")
    return({icon: "fa-regular fa-face-laugh-beam",color: "green"});
  else if(score === "Fair")
    return({icon: "fa-regular fa-face-meh",color: "orange"});
  if(score === "Poor")
    return({icon: "fa-regular fa-face-sad-tear",color: "red"});
}

export const randomIntFromInterval = (min, max)=> { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const intersect = (a, b)=> {
    var t;
    if (b.length > a.length){ t = b; b = a; a = t;} // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    }).filter(function (e, i, c) { // extra step to remove duplicates
      return c.indexOf(e) === i;
    });
}

function App() {
  const CountryProfiles = useRef<[]>([]);
  const CropProfiles = useRef<[]>([]);
  const StrategyCards = useRef<[]>([]);
  const SetBacks = useRef<[]>([]);
  const getJSON=(JSONfile,stateVar)=>{
  fetch(JSONfile,{headers : {'Content-Type': 'application/json', 'Accept': 'application/json'}})
      .then(function(response){
      return response.json();
      })
      .then(function(JSON) {
      eval(stateVar+".current=JSON;");
      });
  };    
  useEffect(()=>{      
      getJSON('DataSources/CountryProfiles.json','CountryProfiles');
      getJSON('DataSources/CropProfiles.json','CropProfiles');
      getJSON('DataSources/StrategyCards.json','StrategyCards');
      getJSON('DataSources/SetBacks.json','SetBacks');
  },[]);

  const [showLanding,setShowLanding] = useState<boolean>(true);
  const [showInstruction,setShowInstruction] = useState<boolean>(false);
  const [showConfiguration,setShowConfiguration] = useState<boolean>(false);
  const [showPreGame,setShowPreGame] = useState<boolean>(false);
  const [showStrategy,setShowStrategy] = useState<boolean>(false);
  
  const [showMission,setShowMission] = useState<boolean>(true);
  const [showGoals,setShowGoals] = useState<boolean>(false);
  const [showGoals2,setShowGoals2] = useState<boolean>(false);
  const [showLetsPlay,setShowLetsPlay] = useState<boolean>(false);
  
  const [showLevels,setShowLevels] = useState<boolean>(true);
  const [showRounds,setShowRounds] = useState<boolean>(false);
  const [selectedLevel,setSelectedLevel] = useState<number>(0);
  const [showPlayers,setShowPlayers] = useState<boolean>(false);
  const [showCountries,setShowCountries] = useState<boolean>(false);  
  const [selectedCountry,setSelectedCountry] = useState<string>('');
  const [showCountryProfile,setShowCountryProfile] = useState<boolean>(false);  

  const [selectedCrop,setSelectedCrop] = useState<any>({ wheat: false, coffee: false, sugarcane: false, rice: false, maize: false });
  const [showPickCrop,setShowPickCrop] = useState<boolean>(true);
  const [showCropMatch,setShowCropMatch] = useState<boolean>(false);
  const [showCultivatedYield,setShowCultivatedYield] = useState<boolean>(false);

  const [benefits,setBenefits] = useState<any>({coins: 0, "Policy Maker": {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}, "Community Leader": {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}, Farmer: {cooperation: 0, economic: 0, environmental: 0, social: 0, climate_adaptation: 0}});
  const [tokens,setTokens] = useState<any>({"Policy Maker": {1: null, 2: null, 3: null}, "Community Leader": {1: null, 2: null, 3: null}, Farmer: {1: null, 2: null, 3: null}});
  const [points,setPoints] = useState<any>({"Policy Maker": {1: null, 2: null, 3: null}, "Community Leader": {1: null, 2: null, 3: null}, Farmer: {1: null, 2: null, 3: null}});
  const [set_backs,setSet_backs] = useState<any>({});
  const [scores,setScores] = useState<any>({"Policy Maker": {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}, "Community Leader": {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}, Farmer: {economic_score: 0, environmental_score: 0, social_score: 0, climate_adaptation_score: 0}});
  const crops = useRef({display: '', cropnames: [], totalscore: 0, pass: false});

  const [policyMakerStrategy,setPolicyMakerStrategy] = useState<any>({budget: 50});
  const [communityLeaderStrategy,setCommunityLeaderStrategy] = useState<any>({budget: 30});
  const [farmerStrategy,setFarmerStrategy] = useState<any>({budget: 20});
  const [disasters,setDisasters] = useState<any>(["flood", "drought", "wildfire"]);
  const disaster = useRef<any>({});
  const [showTimer,setShowTimer] = useState<boolean>(true);
  const [showEvaluation,setShowEvaluation] = useState<boolean>(false);
  const [showStrategyResults,setShowStrategyResults] = useState<boolean>(false);
  const [showCooperationScore,setShowCooperationScore] = useState<boolean>(false);
  const [showCompleteScore,setShowCompleteScore] = useState<boolean>(false);
  const [showDisasterSelection,setShowDisasterSelection] = useState<boolean>(false);
  const [showDisasterProfile,setShowDisasterProfile] = useState<boolean>(false);
  const [showDisasterResult,setShowDisasterResult] = useState<boolean>(false);
  const [showSetBackSelection,setShowSetBackSelection] = useState<boolean>(false);
  const [showFinalScores,setShowFinalScores] = useState<boolean>(false);
  const [showFinalResult,setShowFinalResult] = useState<boolean>(false);

  const [round,setRound] = useState<number>(1);
  const [resetCountry,setResetCountry] = useState<boolean>(false);
  const [resetCrop,setResetCrop] = useState<boolean>(false);
  
  const showComponent = (component)=>{
    eval('setShow'+component+'(true)');
  }
  const hideComponent = (component)=>{
    eval('setShow'+component+'(false)');   
  }
  
  return (
    <>
      {showLanding && <Landing showComponent={showComponent} hideComponent={hideComponent} setShowLanding={setShowLanding} setShowInstruction={setShowInstruction} setShowConfiguration={setShowConfiguration} setShowPreGame={setShowPreGame} setShowStrategy={setShowStrategy} setShowPlayers={setShowPlayers} />}
      {showInstruction && <Instruction
                              showComponent={showComponent}                            
                              hideComponent={hideComponent} 
                              setShowLanding={setShowLanding} 
                              setShowInstruction={setShowInstruction}                              
                              showMission={showMission}
                              setShowMission={setShowMission} 
                              showPlayers={showPlayers} 
                              setShowPlayers={setShowPlayers}
                              showGoals={showGoals}
                              setShowGoal={setShowGoals}
                              showGoals2={showGoals2}
                              setShowGoals2={setShowGoals2}
                              showLetsPlay={showLetsPlay}
                              setShowLetsPlay={setShowLetsPlay}
                              setShowConfiguration={setShowConfiguration}
                              setShowPreGame={setShowPreGame}
                              setShowStrategy={setShowStrategy}
                              />}
      {showConfiguration && <Configuration
                              showComponent={showComponent}                            
                              hideComponent={hideComponent} 
                              setShowLanding={setShowLanding}                               
                              setShowInstruction={setShowInstruction}                              
                              setShowConfiguration={setShowConfiguration}
                              setShowPreGame={setShowPreGame}
                              round={round}
                              showLevels={showLevels}
                              setShowLevels={setShowLevels}
                              showRounds={showRounds} 
                              setShowRounds={setShowRounds}
                              showPlayers={showPlayers} 
                              setShowPlayers={setShowPlayers} 
                              showCountries={showCountries} 
                              setShowCountries={setShowCountries}
                              showCountryProfile={showCountryProfile}
                              setShowCountryProfile={setShowCountryProfile}
                              CountryProfiles={CountryProfiles}
                              selectedLevel={selectedLevel}
                              setSelectedLevel={setSelectedLevel}                              
                              selectedCountry={selectedCountry}
                              setSelectedCountry={setSelectedCountry}
                              setShowStrategy={setShowStrategy}
                              resetCountry={resetCountry}
                              setResetCountry={setResetCountry}
                              />}
      {showPreGame && <PreGame
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowLanding={setShowLanding} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration} 
                              setShowPreGame={setShowPreGame}
                              round={round}
                              selectedLevel={selectedLevel}
                              selectedCountry={selectedCountry}
                              showCountryProfile={showCountryProfile}
                              setShowCountryProfile={setShowCountryProfile}
                              selectedCrop={selectedCrop}
                              setSelectedCrop={setSelectedCrop}
                              showPickCrop={showPickCrop}
                              setShowPickCrop={setShowPickCrop}
                              crops={crops}
                              showCropMatch={showCropMatch}
                              setShowCropMatch={setShowCropMatch}
                              CountryProfiles={CountryProfiles}
                              CropProfiles={CropProfiles}
                              policyMakerStrategy={policyMakerStrategy}
                              setPolicyMakerStrategy={setPolicyMakerStrategy}
                              communityLeaderStrategy={communityLeaderStrategy}
                              setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                              farmerStrategy={farmerStrategy}
                              setFarmerStrategy={setFarmerStrategy}
                              benefits={benefits}
                              setBenefits={setBenefits}                              
                              showCultivatedYield={showCultivatedYield}
                              setShowCultivatedYield={setShowCultivatedYield}
                              setShowStrategy={setShowStrategy}
                              resetCrop={resetCrop}
                              setResetCrop={setResetCrop}
                              />}
      {showStrategy && <Strategy
                              showComponent={showComponent} 
                              hideComponent={hideComponent} 
                              setShowLanding={setShowLanding} 
                              setShowInstruction={setShowInstruction}
                              setShowConfiguration={setShowConfiguration} 
                              setShowPreGame={setShowPreGame}
                              round={round}
                              setRound={setRound}
                              selectedLevel={selectedLevel}
                              setSelectedLevel={setSelectedLevel}
                              selectedCountry={selectedCountry}
                              setSelectedCountry={setSelectedCountry}
                              showCountryProfile={showCountryProfile}
                              setShowCountryProfile={setShowCountryProfile}
                              selectedCrop={selectedCrop}
                              setSelectedCrop={setSelectedCrop}
                              showPickCrop={showPickCrop}
                              setShowPickCrop={setShowPickCrop}
                              crops={crops}
                              showCropMatch={showCropMatch}
                              setShowCropMatch={setShowCropMatch}
                              CountryProfiles={CountryProfiles}
                              CropProfiles={CropProfiles}
                              benefits={benefits}
                              setBenefits={setBenefits}
                              tokens={tokens}
                              setTokens={setTokens}
                              points={points}
                              setPoints={setPoints}
                              scores={scores}
                              setScores={setScores}
                              showCultivatedYield={showCultivatedYield}
                              setShowCultivatedYield={setShowCultivatedYield}
                              showPlayers={showPlayers}
                              setShowPlayers={setShowPlayers}
                              showTimer={showTimer}
                              setShowTimer={setShowTimer}
                              setShowStrategy={setShowStrategy}
                              showEvaluation={showEvaluation}
                              setShowEvaluation={setShowEvaluation}
                              policyMakerStrategy={policyMakerStrategy}
                              setPolicyMakerStrategy={setPolicyMakerStrategy}
                              communityLeaderStrategy={communityLeaderStrategy}
                              setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                              farmerStrategy={farmerStrategy}
                              setFarmerStrategy={setFarmerStrategy}
                              showStrategyResults={showStrategyResults}
                              setShowStrategyResults={setShowStrategyResults}
                              StrategyCards={StrategyCards}
                              showCooperationScore={showCooperationScore}
                              setShowCooperationScore={setShowCooperationScore}
                              showCompleteScore={showCompleteScore}
                              setShowCompleteScore={setShowCompleteScore}
                              showDisasterSelection={showDisasterSelection}
                              setShowDisasterSelection={setShowDisasterSelection}
                              showDisasterProfile={showDisasterProfile}
                              setShowDisasterProfile={setShowDisasterProfile}
                              disaster={disaster}
                              disasters={disasters}
                              showDisasterResult={showDisasterResult}
                              setShowDisasterResult={setShowDisasterResult}
                              SetBacks={SetBacks}
                              set_backs={set_backs}
                              setSet_backs={setSet_backs}
                              showSetBackSelection={showSetBackSelection}
                              setShowSetBackSelection={setShowSetBackSelection}
                              showFinalScores={showFinalScores}
                              setShowFinalScores={setShowFinalScores}
                              showFinalResult={showFinalResult}
                              setShowFinalResult={setShowFinalResult}
                              resetCountry={resetCountry}
                              setResetCountry={setResetCountry}
                              resetCrop={resetCrop}
                              setResetCrop={setResetCrop}
                              />}
    </>
  );
}

export default App;
