import { Button } from 'primereact/button';

export const Mission = (props) => {
  const {showComponent,hideComponent,showMission,setShowMission,showGoals,setShowGoals,showGoals2,setShowGoals2,setShowLanding,setShowPlayers} = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  return (    
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
        <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Your Mission</span>
        </div>
        <div className="cell">
          &nbsp;
        </div>
      </div>

      <div className="content">

          <div className="cell cell3hmerge">
            &nbsp;
          </div>          
          <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processTransition('Mission','Instruction','Landing'))} />
          </div>
          <div className="cell flex-column align-items-center justify-content-center">
            <p className="p-0 mt-3" style={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>In a world transformed by climate change, gear up for the ultimate challenge in <br /><span style={{ fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>AgroAdapt: Cultivate the Future</span></p>
            <p className="p-0 mt-3" style={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>Step into the shoes of a <span style={{ fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Farmer, Community Leader or Policy Maker</span> and brace yourself for a thrilling game of strategy</p>
            <p className="p-0 mt-3" style={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>Your mission is to <span style={{ fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>secure the country&apos;s future</span> through cunning planning and strategic investments</p>
          </div>
          <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('Mission','Players'))} />
          </div>
          <div className="cell cell3hmerge">
            &nbsp;
          </div>
      
      </div>
    </div>
  );
}
