import { CompleteScore } from './CompleteScore';
import { CooperationScore } from './CooperationScore';
import { DisasterProfile } from './DisasterProfile';
import { DisasterResult } from './DisasterResult';
import { DisasterSelection } from './DisasterSelection';
import { Evaluation } from './Evaluation';
import { FinalScores } from './FinalScores';
import { FinalResults } from './FinalResults';
import { Players } from './Players';
import { SetBackSelection } from './SetBackSelection';
import { StrategyResults } from './StrategyResults';
import { Timer } from './Timer';

export const Strategy = (props) => {
  const {
      showComponent,
      hideComponent,
      setShowLanding,
      setShowInstruction,
      setShowConfiguration,
      round,
      setRound,
      selectedLevel,
      setSelectedLevel,    
      selectedCountry,
      setSelectedCountry,
      setShowCountryProfile,
      selectedCrop,
      setSelectedCrop,
      crops,
      showPickCrop,
      setShowPickCrop,    
      setShowMissionDialog,
      showCropMatch,
      setShowCropMatch,
      CountryProfiles,
      CropProfiles,
      benefits,
      setBenefits,
      tokens,
      setTokens,
      points,
      setPoints,
      scores,
      setScores,
      showCultivatedYield,
      setShowCultivatedYield,
      showPlayers,
      setShowPlayers,
      setShowPreGame,
      showTimer,
      setShowTimer,
      setShowStrategy,
      showEvaluation,
      setShowEvaluation,
      policyMakerStrategy,
      setPolicyMakerStrategy,
      communityLeaderStrategy,
      setCommunityLeaderStrategy,
      farmerStrategy,
      setFarmerStrategy,
      showStrategyResults,
      setShowStrategyResults,
      StrategyCards,
      showCooperationScore,
      setShowCooperationScore,
      showCompleteScore,
      setShowCompleteScore,
      showDisasterSelection,
      setShowDisasterSelection,
      showDisasterProfile,
      setShowDisasterProfile,
      disaster,
      disasters,
      showDisasterResult,
      setShowDisasterResult,
      SetBacks,
      set_backs,
      setSet_backs,
      showSetBackSelection,
      setShowSetBackSelection,
      showFinalScores,
      setShowFinalScores,
      showFinalResult,
      setShowFinalResult,
      resetCountry,
      setResetCountry,
      resetCrop,
      setResetCrop
    } = props;
  setShowLanding(false);
  setShowInstruction(false);
  setShowConfiguration(false); 
  setShowPreGame(false);
  //setShowStrategy(false);   
  if(showPlayers === false && showTimer === false && showStrategyResults === false && showCooperationScore === false && showEvaluation === false && showCompleteScore === false && showDisasterSelection === false && showDisasterProfile === false && showDisasterResult === false && showSetBackSelection === false && showFinalScores === false && showFinalResult === false){
    setShowTimer(true);
  }
  return (
    <>
      {showPlayers && <Players
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        showPlayers={showPlayers}
                        setShowPlayers={setShowPlayers}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        StrategyCards={StrategyCards}
                        />}
      {showTimer && <Timer
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}                        
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        showPlayers={showPlayers}
                        setShowPlayers={setShowPlayers}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        StrategyCards={StrategyCards}
                        />}      
      {showStrategyResults && <StrategyResults
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}         
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        />}
      {showCooperationScore && <CooperationScore
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        showEvaluation={showEvaluation}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}         
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        />}
    {showEvaluation && <Evaluation
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}                        
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        />}
    {showCompleteScore && <CompleteScore
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        />}
    {showDisasterSelection && <DisasterSelection
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        showDisasterProfile={showDisasterProfile}
                        setShowDisasterProfile={setShowDisasterProfile}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        />}
    {showDisasterProfile && <DisasterProfile
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        showDisasterProfile={showDisasterProfile}
                        setShowDisasterProfile={setShowDisasterProfile}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        />}
    {showDisasterResult && <DisasterResult
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        showDisasterProfile={showDisasterProfile}
                        setShowDisasterProfile={setShowDisasterProfile}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        SetBacks={SetBacks}
                        set_backs={set_backs}
                        setSet_backs={setSet_backs}
                        showSetBackSelection={showSetBackSelection}
                        setShowSetBackSelection={setShowSetBackSelection}
                        />}
    {showSetBackSelection && <SetBackSelection
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        selectedLevel={selectedLevel}
                        selectedCountry={selectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        SetBacks={SetBacks}
                        set_backs={set_backs}
                        setSet_backs={setSet_backs}
                        showSetBackSelection={showSetBackSelection}
                        setShowSetBackSelection={setShowSetBackSelection}
                        showFinalScores={showFinalScores}
                        setShowFinalScores={setShowFinalScores}
                        />}
    {showFinalScores && <FinalScores
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        setRound={setRound}
                        selectedLevel={selectedLevel}
                        setSelectedLevel={setSelectedLevel}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        SetBacks={SetBacks}
                        set_backs={set_backs}
                        setSet_backs={setSet_backs}
                        showSetBackSelection={showSetBackSelection}
                        setShowSetBackSelection={setShowSetBackSelection}
                        showFinalResult={showFinalResult}
                        setShowFinalResult={setShowFinalResult}
                        resetCountry={resetCountry}
                        setResetCountry={setResetCountry}
                        resetCrop={resetCrop}
                        setResetCrop={setResetCrop}
                        />}
    {showFinalResult && <FinalResults
                        showComponent={showComponent} 
                        hideComponent={hideComponent} 
                        setShowInstruction={setShowInstruction}
                        setShowConfiguration={setShowConfiguration}
                        setShowCountryProfile={setShowCountryProfile}
                        round={round}
                        setRound={setRound}
                        selectedLevel={selectedLevel}
                        setSelectedLevel={setSelectedLevel}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        crops={crops}
                        setShowPickCrop={setShowPickCrop}
                        setShowMissionDialog={setShowMissionDialog}
                        showCropMatch={showCropMatch}
                        setShowCropMatch={setShowCropMatch}
                        CountryProfiles={CountryProfiles}
                        CropProfiles={CropProfiles}
                        benefits={benefits}
                        setBenefits={setBenefits}
                        tokens={tokens}
                        setTokens={setTokens}
                        points={points}
                        setPoints={setPoints}
                        scores={scores}
                        setScores={setScores}
                        showCultivatedYield={showCultivatedYield}
                        setShowCultivatedYield={setShowCultivatedYield}
                        setShowTimer={setShowTimer}
                        setShowPreGame={setShowPreGame}
                        setShowStrategy={setShowStrategy}
                        setShowEvaluation={setShowEvaluation}
                        policyMakerStrategy={policyMakerStrategy}
                        setPolicyMakerStrategy={setPolicyMakerStrategy}
                        communityLeaderStrategy={communityLeaderStrategy}
                        setCommunityLeaderStrategy={setCommunityLeaderStrategy}
                        farmerStrategy={farmerStrategy}
                        setFarmerStrategy={setFarmerStrategy}
                        showStrategyResults={showStrategyResults}
                        setShowStrategyResults={setShowStrategyResults}
                        StrategyCards={StrategyCards}
                        showCooperationScore={showCooperationScore}
                        setShowCooperationScore={setShowCooperationScore}
                        showCompleteScore={showCompleteScore}
                        setShowCompleteScore={setShowCompleteScore}
                        showDisasterSelection={showDisasterSelection}
                        setShowDisasterSelection={setShowDisasterSelection}
                        disaster={disaster}
                        disasters={disasters}
                        showDisasterResult={showDisasterResult}
                        setShowDisasterResult={setShowDisasterResult}
                        SetBacks={SetBacks}
                        set_backs={set_backs}
                        setSet_backs={setSet_backs}                        
                        showFinalScores={showFinalScores}
                        setShowFinalScores={setShowFinalScores}
                        showFinalResult={showFinalResult}
                        setShowFinalResult={setShowFinalResult}
                        resetCountry={resetCountry}
                        setResetCountry={setResetCountry}
                        resetCrop={resetCrop}
                        setResetCrop={setResetCrop}
                        />}
    </>
  )
}
