import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const Countries = (props) => {
  const {showComponent,hideComponent,setShowLevels,setShowCountries,round,selectedLevel,setSelectedLevel,selectedCountry,setSelectedCountry,setShowInstruction,resetCountry} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }
  const processSelection = (country)=>{
    setSelectedCountry(country);
    processClick('Countries','CountryProfile');
  }

  return (   
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Pick a Country</span>
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center', color: '#555' }}>Time to choose a country to cultivate some crops</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
        </div>
      </div>

      <div className="content">
 
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('Countries','Rounds'))} />
          </div>
          
          <div className="cell cell2vmerge flex-row gap-5">
                      
              <div className="flex flex-column align-items-center hover:bg-green-400 p-1" style={{ width: '20vw', height: '67vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', cursor: 'pointer' }} onClick={()=>(processSelection('Rainlushia'))}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw', opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8 }}><img src='images/rainlushia.png' alt='Rainlushia' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Rainlushia</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Rainlushia is a lush, tropical paradise with abundant rainfall throughout the year. Its fertile soils and warm climate make it ideal for agriculture.</p>
                <p className="p-0 mt-3" style={{ fontSize: '1vw', fontWeight: '900', textAlign: 'center', width: '75%', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Agricultural productivity:<br/>High</p>
              </div>
              <div className="flex flex-column align-items-center hover:bg-green-400 p-1" style={{ width: '20vw', height: '67vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', cursor: 'pointer' }} onClick={()=>(processSelection('Sereinplains'))}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw',opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8}}><img src='images/sereinplains.png' alt='Sereinplains' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Sereinplains</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Sereinplains is a vast, idyllic grassland with fertile soil and a great climate, ideal for agriculture.</p>
                <p className="p-0 mt-3" style={{ fontSize: '1vw', fontWeight: '900', textAlign: 'center', width: '75%', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Agricultural productivity:<br/>Moderate</p>
              </div>
              <div className="flex flex-column align-items-center hover:bg-green-400 p-1" style={{ width: '20vw', height: '67vh', opacity: '0.85', background: '#FFE6A5', borderRadius: '20px', cursor: 'pointer' }} onClick={()=>(processSelection('Floralandia'))}>
                <div className="flex align-items-center justify-content-center" style={{width: '15vw', opacity: '0.85', background: '#FFFCF3', borderRadius: '20px', marginTop: 8}}><img src='images/floralandia.png' alt='Floralandia' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 mt-3" style={{ fontSize: '1.5vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Floralandia</p>
                <p className="p-0 m-0" style={{ fontSize: '1vw', fontWeight: '600', textAlign: 'center', width: '75%' }}>Floralandia is a combination of grassland and rainforest for a thriving blend of farming and biodiversity.</p>
                <p className="p-0 mt-3" style={{ fontSize: '1vw', fontWeight: '900', textAlign: 'center', width: '75%', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Agricultural productivity:<br/>Moderate</p>
              </div>            

          </div>
          <div className="cell cell2vmerge">
            &nbsp;
          </div>
          <div className="cell cell3hmerge">
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('Rounds','Configuration','Instruction'))} rounded raised />
          </div> 
      
      </div>
    </div>
  )
}
