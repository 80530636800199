import { useRef,useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

export const CultivatedYield = (props) => {
  const {showComponent,hideComponent,setShowInstruction,setShowConfiguration,round,selectedLevel,selectedCountry,selectedCrop,crops,setShowPickCrop,setSelectedCrop,setShowLevel,CountryProfiles,CropProfiles,benefits,setBenefits,showCultivatedYield,setShowCultivatedYield} = props;
  
  const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  const CalculateYield = (country,crop)=>{    
    let productiveArea = Math.round(CountryProfiles.current[country].area * 100 * (CountryProfiles.current[country].intensity_of_cropland / 100));
    let areaPlanted = Math.ceil((productiveArea * 0.4)/100)*100;
    let barrenArea = Math.ceil((productiveArea * 0.1)/100)*100;
    let cropYield = Math.ceil((areaPlanted * (CountryProfiles.current[country][round][selectedLevel][crop] / 100))/100)*100;
    return { productive_area: productiveArea, area_planted: areaPlanted, crop_yield: cropYield, barren_area: barrenArea };
  }

  let cropYield = [];
  let totalYield = 0;
  //let barrenArea = Math.ceil((CountryProfiles.current[selectedCountry].area * 100 * 0.2)/100)*100;  
  Object.entries(selectedCrop).map(crop=>{    
    if(crop[1] === true){
      let tempYield = CalculateYield(selectedCountry,crop[0]);
      cropYield = {...cropYield, [crop[0]]: tempYield};
      totalYield = totalYield+tempYield.crop_yield;
    }
  });
  crops.current['cropYield'] = cropYield;
  crops.current['totalYield'] = totalYield;

  return (
    <div className="container" style={{ backgroundImage: "url('images/full_background.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Cultivated Area Yield Output</span>
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center', color: '#555' }}>Your maximum potential production</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
        </div>
      </div>

      <div className="content">
 
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('CultivatedYield','CropMatch'))} />
        </div>         
        <div className="cell cell2vmerge flex-column">
          <div className="cell flex-row">
            <div className="flex flex-column justify-content-center" style={{width: '13vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }} >
              <div className="flex align-self-center" style={{ maxWidth: '10vw', maxHeight: '10vw', background: '#FFFCF3', borderRadius: '20px'}}><img src={'images/'+crops.current['cropNames'][0].toLowerCase()+'.png'} alt={crops.current['cropNames'][0]} style={{maxWidth: '100%', padding: 10, textAlign: 'center'}} /></div>
              <p className="p-0 mt-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center' }}>{crops.current['cropNames'][0]}</p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Cultivated areas<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>{crops.current.cropYield[crops.current['cropNames'][0].toLowerCase()].area_planted.toLocaleString()} ha</span></p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Max potential yield<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>{crops.current.cropYield[crops.current['cropNames'][0].toLowerCase()].crop_yield.toLocaleString()} tonnes</span></p>
            </div>
            <div className="flex flex-column justify-contents-center" style={{width: '13vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }} >
              <div className="flex align-self-center" style={{width: '10vw', maxHeight: '10vw', background: '#FFFCF3', borderRadius: '20px'}}><img src={'images/'+crops.current['cropNames'][1].toLowerCase()+'.png'} alt={crops.current['cropNames'][1]} style={{maxWidth: '100%', padding: 10}} /></div>
              <p className="p-0 mt-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center' }}>{crops.current['cropNames'][1]}</p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Cultivated areas<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>{crops.current.cropYield[crops.current['cropNames'][1].toLowerCase()].area_planted.toLocaleString()} ha</span></p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Max potential yield<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>{crops.current.cropYield[crops.current['cropNames'][1].toLowerCase()].crop_yield.toLocaleString()} tonnes</span></p>
            </div>
            <div className="flex flex-column justify-contents-center" style={{width: '13vw', height: 'fit-content', minHeight: '300px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }} >
              <div className="flex align-self-center" style={{width: '10vw', maxHeight: '10vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/barren_land.png' alt='Barren Land' style={{maxWidth: '100%', padding: 10}} /></div>
              <p className="p-0 mt-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center' }}>Fallow Land</p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Non-cultivated areas<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>{(crops.current.cropYield[crops.current['cropNames'][0].toLowerCase()].barren_area+crops.current.cropYield[crops.current['cropNames'][1].toLowerCase()].barren_area).toLocaleString()} ha</span></p>
              <p className="p-0 mt-1" style={{ fontSize: '1vw', textAlign: 'left' }}>Max potential yield<br/><span style={{ fontSize: '1vw', fontWeight: 'bolder' }}>0 tonnes</span></p>
            </div>            
          </div>
          <div className="cell cell3hmerge" style={{ width: '600px'}}>
            <div className="cell" style={{width: '80%', height: 'fit-content', background: '#FFE6A5', borderRadius: '20px', padding: 10}} >
              <p className="p-0 mt-1" style={{ fontSize: '1.5vw', textAlign: 'center' }}>Max potential yield<br/><span style={{ fontSize: '2vw', fontWeight: 'bolder' }}>{crops.current.totalYield.toLocaleString()} tonnes</span></p>
            </div>
            <div className="z-1 absolute flex flex-wrap align-items-center justify-contents-center p-3 m-0" style={{ right: '250px', bottom: '300px', transform: "rotate(15deg)", backgroundColor: 'orange', borderRadius: '50%', width: '13vw', height: '13vw', opacity: '0.9' }}>
                <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1vw', fontWeight: 'bold', textAlign: 'center' }}><i className="fa-solid fa-bullseye" style={{ fontSize: '2.5rem' }}></i><br/>GOAL!!<br/>&quot;Secure maximum production despite disasters with strategic planning.&quot;</p>
            </div>
          </div>
        </div>
        <div className="cell cell2vmerge">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processTransition('CultivatedYield','PreGame','Strategy'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('CultivatedYield','PreGame','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('CultivatedYield','PreGame','Configuration'))} rounded raised />
          <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="Time to Strategise!" onClick={()=>(processTransition('CultivatedYield','PreGame','Strategy'))} rounded raised />
        </div>
      
      </div>
    </div>
  )
}
