import { useEffect, useState, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const DisasterSelection = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    showDisasterProfile,
    setShowDisasterProfile,
    disaster,
    disasters,
    showDisasterResult,
    setShowDisasterResult
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  /*let range = 0;
  disasters.map(disaster=>{
    range = range+CountryProfiles.current[selectedCountry][disaster];
  });
  let selection = randomIntFromInterval(0, range)
  let randomDisaster = '';
  if(selection <= CountryProfiles.current[selectedCountry]['flood'])
    randomDisaster = "flood";
  else if(selection <= CountryProfiles.current[selectedCountry]['drought'])
    randomDisaster = "drought";
  else
    randomDisaster = "wildfire";*/

  let randomDisaster = '';
  let selection = randomIntFromInterval(1, 10)*10;
  if(selection > (CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['medium']['probability']+CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['low']['probability']))
    randomDisaster = CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['high']['type'];
  else if(selection <= (CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['medium']['probability']+CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['low']['probability']) && selection > CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['low']['probability'])
    randomDisaster = CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['medium']['type'];
  else
    randomDisaster = CountryProfiles.current[selectedCountry][round][selectedLevel]['disaster']['low']['type'];

  let min_intensity = 0;
  let max_intensity = 0;
  let max_events = 0;
  let min_severity = Math.ceil(CountryProfiles.current[selectedCountry][randomDisaster]/4*(selectedLevel-1));
  let max_severity = Math.ceil(CountryProfiles.current[selectedCountry][randomDisaster]/4*selectedLevel);
  if(selectedLevel === 4){
    min_intensity = 70;
    max_intensity = 95;
    max_events = 11;
  }
  else{
    min_intensity = 5+selectedLevel*5;
    max_intensity = 15+selectedLevel*5;
    max_events = 3+round;
  }

  const events = useRef(randomIntFromInterval(1,max_events));

  let tempDisasterDetails = {};
  for (let i = 1; i <= events.current; i++) {
    let severity = randomIntFromInterval(min_severity,max_severity);
    let intensity = randomIntFromInterval(min_intensity,max_intensity);
    tempDisasterDetails = {...tempDisasterDetails, [i]: {severity: severity, intensity: intensity}};
  }
  disaster.current = ({disaster: randomDisaster, event_number: events.current, events: tempDisasterDetails});

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social;
  
  return (
    <div className="container" style={{ backgroundImage: "url('images/disaster/"+randomDisaster+".png')", backgroundSize: "100% 100%", backgroundColor: "#ccc" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center', color: '#aaa' }}>Oh! Oh! Climatic Event Incoming.</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell3hmerge flex-column">          
          <p className="p-0 m-0" style={{ color: '#eee', fontSize: '1.5vw', textAlign: 'center', textShadow: '2px 2px 4px #000' }}>Your Country is facing</p>
          <p className="p-0 m-0" style={{ color: '#fff', fontSize: '2vw', textAlign: 'center', fontWeight: '700', textShadow: '2px 2px 4px #000' }}>{randomDisaster.charAt(0).toUpperCase()+randomDisaster.slice(1)}!!</p>
        </div>
        
        <div className="cell">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#aaa", color: "#333"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('DisasterSelection','CompleteScore'))} />
        </div>         
        <div className="cell flex-column">

          <div className="flex flex-row align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-2" style={{ height: '30vh', width: '40vw', borderRadius: '20px', color: 'white', backgroundImage: 'linear-gradient(rgba(50,50,50,1), rgba(50,50,50,0))', }}>
            <div className="flex align-items-end justify-content-center p-0 m-0" style={{ width:'30vw' }}>
              <img src={'images/'+randomDisaster+'2.png'} alt={randomDisaster} style={{ borderRadius: '50%', width: '15vw' }} />
            </div>
            <div className="flex flex-column align-items-center justify-content-center p-0 m-0" style={{ width:'70%' }}>
                <p className="p-0 m-0" style={{ color: '#fff',textAlign: 'center', fontSize: '2vw' }}>Potential Climate Exposure</p>
                <h1 className="p-0 m-0 flex" style={{ color: '#fff', fontSize: '3vw', fontWeight: 'bold' }}>{CountryProfiles.current[selectedCountry][round][selectedLevel][randomDisaster]}%</h1>
            </div>
          </div>

          <p className="p-0 m-0 mt-3" style={{ color: '#fff', fontSize: '2vw', textAlign: 'center', textShadow: '2px 2px 4px #000' }}>Let's see how severely the area was affected and how frequently the climate event occurred</p>          

        </div>
        <div className="cell">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#aaa", color: "#333"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('DisasterSelection','DisasterProfile'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Mission" onClick={()=>(processTransition('DisasterSelection','Strategy','Instruction'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Selections" onClick={()=>(processTransition('DisasterSelection','Strategy','Configuration'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Strategies" onClick={()=>(processClick('DisasterSelection','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
