import { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ToggleButton, ToggleButtonChangeEvent } from 'primereact/togglebutton';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { InputText } from 'primereact/inputtext';

export const PickCrop = (props) => {
  const {showComponent,hideComponent,setShowInstruction,setShowConfiguration,round,selectedLevel,selectedCountry,selectedCrop,setShowPickCrop,setShowMissionDialog,setSelectedCrop,setShowCropMatch,policyMakerStrategy,
    benefits,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy} = props;
  
  const processClick = (hide,show)=>{
    setPolicyMakerStrategy({...policyMakerStrategy, budget: playerBalance.current['Policy Maker'] });
    setCommunityLeaderStrategy({...communityLeaderStrategy, budget: playerBalance.current['Community Leader'] });
    setFarmerStrategy({...farmerStrategy, budget: playerBalance.current['Farmer'] });
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    setPolicyMakerStrategy({...policyMakerStrategy, budget: playerBalance.current['Policy Maker'] });
    setCommunityLeaderStrategy({...communityLeaderStrategy, budget: playerBalance.current['Community Leader'] });
    setFarmerStrategy({...farmerStrategy, budget: playerBalance.current['Farmer'] });
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  } 

  const [cropCount,setCropCount] = useState(0);
  const [disablemaize,setDisablemaize] = useState(false);
  const [disablecoffee,setDisablecoffee] = useState(false);
  const [disablerice,setDisablerice] = useState(false);
  const [disablesugarcane,setDisablesugarcane] = useState(false);
  const [disablewheat,setDisablewheat] = useState(false);
  const [disableAdvance,setDisableAdvance] = useState(true);
  useEffect(()=>{
    let count = 0;
    Object.entries(selectedCrop).map(crop=>{
      if(crop[1] === true){
        count++;
        eval('setDisable'+crop[0]+'(false)');
      }
      else{
        if(count > 1)
          eval('setDisable'+crop[0]+'(true)');        
        else
          eval('setDisable'+crop[0]+'(false)');        
      }
    }); 
    setCropCount(count);
  },[selectedCrop.maize,selectedCrop.rice,selectedCrop.coffee,selectedCrop.wheat,selectedCrop.sugarcane]);
  useEffect(()=>{
    if(cropCount > 1){
      Object.entries(selectedCrop).map(crop=>{
        if(crop[1] === false)
          eval('setDisable'+crop[0]+'(true)');        
      });
    }
    if(cropCount > 1)
      setDisableAdvance(false);
    else if(cropCount < 2)
      setDisableAdvance(true);
  },[cropCount]);

  const handleCropToggle = (value,crop)=>{
    setSelectedCrop((prevState) => ({
        ...prevState,
        [crop]: value
    }));
    setPolicyMakerStrategy({...policyMakerStrategy, budget: playerBalance.current['Policy Maker'] });
    setCommunityLeaderStrategy({...communityLeaderStrategy, budget: playerBalance.current['Community Leader'] });
    setFarmerStrategy({...farmerStrategy, budget: playerBalance.current['Farmer'] });
  }
  
  const {
      control,
      formState: { errors },
      handleSubmit,
      getValues,
      setValue,
      reset
  } = useForm();
  
  const [PM, setPM] = useState(null);
  const [CL, setCL] = useState(null);
  let playerBalance = useRef({
    "Policy Maker":policyMakerStrategy.budget,
    "Community Leader":communityLeaderStrategy.budget,
    "Farmer":farmerStrategy.budget
  });
  const [overBudget,setOverBudget] = useState(false);  
  const calculateBalance = (target)=>{
    let player = target;
    let Player = "";
    if(player === "PM")
      Player = "Policy Maker";
    else if(player === "CL")
      Player = "Community Leader";    

    let balance = playerBalance.current[Player];
    
    let incentive = parseInt((document.getElementById(player) as HTMLInputElement).value);
    if(incentive >= 0){
      balance = balance - incentive;
      if(balance <= 1){
        alert(Player+" is over budget!");
        setValue(target,0);
        eval('set'+target+'(0)');
        setOverBudget(true);
      }
      else
      {
        setOverBudget(false);        
        playerBalance.current[Player] = balance;
        setValue(target,0);
        if(player === "PM")          
          playerBalance.current["Community Leader"] = playerBalance.current["Community Leader"]+incentive;        
        else if(player === "CL")          
          playerBalance.current["Farmer"] = playerBalance.current["Farmer"]+incentive;        
      }
    }
    else
      alert("No take backs! Your decision is irrevocable.");
  }  

  return (
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Pick Two Crops</span>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+cropCount} icon="fa-solid fa-seedling" style={{height: '25px'}} /></div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell3hmerge">
          <p style={{ fontSize: '1.5vw', textAlign: 'center' }}>Time to choose two types of crop to cultivate.<br/>
            Allocate 40% of the agricultural land to the cultivation of two specific crops each, with the remaining 20% left uncultivated.</p>
        </div> 
        <div className="cell">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processTransition('PickCrop','PreGame','Configuration'))} />
        </div>         
        <div className="cell flex-column align-items-center justify-content-center">
          <div className="flex flex-row p-0 m-0 gap-1" style={{ height: 'fit-content', maxHeight: '20%', width: '70%'  }}>
            <Controller
                name="PM"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}>Policy Maker Incentive:</span>                          
                          <InputText id={field.name} name={field.name} value={field.value} defaultValue={0} size={2}  className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setPM(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} required />                          
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}><i className='fa fa-coins' />{playerBalance.current['Policy Maker']}</span>
                    </div>
                )}
            />
            <Controller
                name="CL"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}>Community Leader Incentive:</span>
                          <InputText id={field.name} name={field.name} value={field.value} defaultValue={0} size={2} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} required />
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}><i className='fa fa-coins' />{playerBalance.current['Community Leader']}</span>
                    </div>
                )}
            />
            <Controller
                name="F"
                control={control}
                render={({ field, fieldState }) => (
                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}>Farmer:</span>
                        <div style={{ width: '10px' }}>
                          <InputText id={field.name} name={field.name} value={field.value} defaultValue={null} size={2} className="p-inputtext-sm text-l p-0 m-0" onChange={(e) => field.onChange(e.target.value)} onBlur={(e) => {setCL(e.target.value);calculateBalance(e.target.name);}} keyfilter="int" min={1} disabled />                          
                        </div>
                        <span className="p-inputgroup-addon p-0 m-0 bg-green-100" style={{fontSize: '1vw'}}><i className='fa fa-coins' />{playerBalance.current['Farmer']}</span>
                    </div>
                )}
            />
          </div>

          <div className="grid grid-nogutter p-0 m-0" style={{ height: 'fit-content', maxHeight: "30%", width: "100%"}}>
            <div className="col-4 flex flex-column align-items-center justify-content-center">
              <img className="block" src={'images/wheat.png'} alt='Wheat' style={{maxWidth: '30%'}} />
              <ToggleButton name="Wheat" className="bg-green-200 hover:bg-green-600 hover:text-white border-green-800 select:bg-green-700 m-1" onLabel="Wheat" offLabel="Wheat" onIcon="pi pi-check" offIcon="pi pi-minus-circle" checked={selectedCrop.wheat} onChange={(e) => handleCropToggle(e.value,'wheat') } disabled={disablewheat} />              
            </div>
            <div className="col-4 flex flex-column align-items-center justify-content-center">
              <img className="block" src={'images/coffee.png'} alt='coffee' style={{maxWidth: '30%'}} />
              <ToggleButton name="coffee" className="bg-green-200 hover:bg-green-600 hover:text-white border-green-800 select:bg-green-700 m-1" onLabel="Coffee" offLabel="Coffee" onIcon="pi pi-check" offIcon="pi pi-minus-circle" checked={selectedCrop.coffee} onChange={(e) => handleCropToggle(e.value,'coffee') } disabled={disablecoffee} />
            </div>
            <div className="col-4 flex flex-column align-items-center justify-content-center">
              <img className="block" src={'images/sugarcane.png'} alt='Sugarcane' style={{maxWidth: '30%'}} />
              <ToggleButton name="sugarcane" className="bg-green-200 hover:bg-green-600 hover:text-white border-green-800 select:bg-green-700 m-1" onLabel="Sugarcane" offLabel="Sugarcane" onIcon="pi pi-check" offIcon="pi pi-minus-circle" checked={selectedCrop.sugarcane} onChange={(e) => handleCropToggle(e.value,'sugarcane') } disabled={disablesugarcane} />
            </div>
          </div>
          <div className="grid grid-nogutter p-0 m-0" style={{ height: 'fit-content', maxHeight: "30%", width: "100%"}}>
            <div className="col-2">&nbsp;</div>
            <div className="col-4 flex flex-column align-items-center justify-content-center">
              <img className="block" src={'images/rice.png'} alt='Rice' style={{maxWidth: '30%'}} />
              <ToggleButton name="Rice" className="bg-green-200 hover:bg-green-600 hover:text-white border-green-800 select:bg-green-700 m-1" onLabel="Rice" offLabel="Rice" onIcon="pi pi-check" offIcon="pi pi-minus-circle" checked={selectedCrop.rice} onChange={(e) => handleCropToggle(e.value,'rice') } disabled={disablerice} />
            </div>
            <div className="col-4 flex flex-column align-items-center justify-content-center">
              <img className="block" src={'images/maize.png'} alt='Maize' style={{maxWidth: '30%'}} />
              <ToggleButton name="Maize" className="bg-green-200 hover:bg-green-600 hover:text-white border-green-800 select:bg-green-700 m-1" onLabel="Maize" offLabel="Maize" onIcon="pi pi-check" offIcon="pi pi-minus-circle" checked={selectedCrop.maize} onChange={(e) => handleCropToggle(e.value,'maize') } disabled={disablemaize} />
            </div>
            <div className="col-2">&nbsp;</div>
          </div>            
        </div>
        
        <div className="cell">
          <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" text onClick={()=>(processClick('PickCrop','CropMatch'))} rounded raised disabled={disableAdvance} />
        </div>

        <div className="cell cell3hmerge">
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('PickCrop','PreGame','Instruction'))} rounded raised />
          <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('PickCrop','PreGame','Configuration'))} rounded raised />           
          <Button className="bg-green-700 hover:bg-green-600 border-green-800 m-1" label="Lets do some farming" icon="fa-solid fa-person-digging" iconPos="right" onClick={()=>(processClick('PickCrop','CropMatch'))} rounded raised disabled={disableAdvance} />
        </div> 
      
      </div>
    </div>
  )
}
