import { useEffect, useState, useRef, Component } from 'react';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';

export const DisasterProfile = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    scores,
    setScores,    
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore,
    showDisasterSelection,
    setShowDisasterSelection,
    showDisasterProfile,
    setShowDisasterProfile,
    disaster,
    disasters,
    showDisasterResult,
    setShowDisasterResult
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  const displayDisasterDetails = useRef("");
  let tempDisasterDetails = {};
  let tempDisplayDisasterDetails = "";
  let tempYield = {};
  let tempLoss = {};

  const displayDisasterLoss = useRef("");
  let totalLoss = {};
  let totalProduction ={};
  let tempDisplayDisasterLoss = "";
  let productionCycles = 30;
  for (let i = 1; i <= disaster.current.event_number; i++) {
    let cropEffect = {};
    let displayCropEffect = "";
    let displayTotalCropEffect = "";    
    Object.entries(crops.current.cropYield).forEach((crop)=>{
            
      if(tempLoss[crop[0]] === undefined){
        tempLoss = {...tempLoss, [crop[0]]: 0};
        totalLoss = {...totalLoss, [crop[0]]: 0}
      }
      if(tempYield[crop[0]] === undefined)
        tempYield = {...tempYield, [crop[0]]: crop[1]['crop_yield']};

      //Reductive loss, each event reduces total yield
      /*let calcEventYield = tempYield[crop[0]]-tempLoss[crop[0]];*/
      //Non-Reductive cyclical loss, each event reduces each cycles yield          
      let calcEventYield = tempYield[crop[0]];

      let yield_affected = Math.ceil((calcEventYield*disaster.current.events[i].intensity/100)/100)*100;
      tempLoss = {...tempLoss, [crop[0]]: yield_affected};
      totalLoss = {...totalLoss, [crop[0]]: totalLoss[crop[0]]+yield_affected};
      tempYield = {...tempYield, [crop[0]]: calcEventYield};
      cropEffect = {...cropEffect, [crop[0]]: tempLoss[crop[0]]};
      displayCropEffect = displayCropEffect+yield_affected.toLocaleString()+" out of "+calcEventYield.toLocaleString()+" tonnes of "+crop[0]+" lost! ";
     
      totalProduction = {...totalProduction, [crop[0]]: crop[1]['crop_yield']*productionCycles};

      //displayTotalCropEffect = displayTotalCropEffect+totalLoss[crop[0]].toLocaleString()+" out of "+totalProduction[crop[0]].toLocaleString()+" tonnes of "+crop[0]+" lost!<br/>";
      displayTotalCropEffect = displayTotalCropEffect+Math.ceil(totalLoss[crop[0]]/totalProduction[crop[0]]*100).toLocaleString()+" % of "+crop[0]+" lost!<br/>";

    });
    tempDisasterDetails = {...tempDisasterDetails, [i]: {severity: disaster.current.events[i].severity, intensity: disaster.current.events[i].intensity, crops_affected: cropEffect}};    
    //tempDisplayDisasterDetails = tempDisplayDisasterDetails+"<b>Event "+i+"</b>: "+disaster.current.events[i].intensity+"% of the cropland affected. "+displayCropEffect+"<br/>";
    tempDisplayDisasterDetails = tempDisplayDisasterDetails+"<b>Event "+i+"</b>: "+disaster.current.events[i].intensity+"% of the cropland affected.<br/>";    
    tempDisplayDisasterLoss = displayTotalCropEffect;
  }
  displayDisasterDetails.current = tempDisplayDisasterDetails;
  displayDisasterLoss.current = tempDisplayDisasterLoss;

  disaster.current = {disaster: disaster.current.disaster, event_number: disaster.current.event_number, events: tempDisasterDetails, crops_affected: totalLoss, crop_production: totalProduction};

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend+benefits['Policy Maker'].cooperation+benefits['Policy Maker'].economic+benefits['Policy Maker'].environmental+benefits['Policy Maker'].social;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend+benefits['Community Leader'].cooperation+benefits['Community Leader'].economic+benefits['Community Leader'].environmental+benefits['Community Leader'].social;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend+benefits['Farmer'].cooperation+benefits['Farmer'].economic+benefits['Farmer'].environmental+benefits['Farmer'].social;

  return (
    <div className="container" style={{ backgroundImage: "url('images/disaster/"+disaster.current.disaster+".png')", backgroundSize: "100% 100%", backgroundColor: "#ccc" }}>
      <div className="header-row">
        <div>
          <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center', color: '#aaa' }}>Oh! Oh! Climatic Event Incoming.</span>          
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend+'+'+benefits['Policy Maker'].cooperation+'+'+benefits['Policy Maker'].economic+'+'+benefits['Policy Maker'].environmental+'+'+benefits['Policy Maker'].social+' ['+policyMakerStrategy[1]+', '+policyMakerStrategy[2]+', '+policyMakerStrategy[3]+', '+policyMakerStrategy[4]+', '+policyMakerStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend+'+'+benefits['Community Leader'].cooperation+'+'+benefits['Community Leader'].economic+'+'+benefits['Community Leader'].environmental+'+'+benefits['Community Leader'].social+' ['+communityLeaderStrategy[1]+', '+communityLeaderStrategy[2]+', '+communityLeaderStrategy[3]+', '+communityLeaderStrategy[4]+', '+communityLeaderStrategy[5]+']'} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend+'+'+benefits['Farmer'].cooperation+'+'+benefits['Farmer'].economic+'+'+benefits['Farmer'].environmental+'+'+benefits['Farmer'].social+' ['+farmerStrategy[1]+', '+farmerStrategy[2]+', '+farmerStrategy[3]+', '+farmerStrategy[4]+', '+farmerStrategy[5]+']'} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">

        <div className="cell cell3hmerge flex-column">          
          <p className="p-0 m-0" style={{ color: '#eee', fontSize: '1.5vw', textAlign: 'center', textShadow: '2px 2px 4px #000' }}>Your Country is facing</p>
          <p className="p-0 m-0" style={{ color: '#fff', fontSize: '2vw', textAlign: 'center', fontWeight: '700', textShadow: '2px 2px 4px #000' }}>{disaster.current.disaster.charAt(0).toUpperCase()+disaster.current.disaster.slice(1)}!!</p>
        </div>
        
        <div className="cell">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#ddd", color: "#333"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('DisasterProfile','DisasterSelection'))} />
        </div>   

        <div className="cell flex-column">

          <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-2" style={{ height: '4vw', width: '50%', borderRadius: '20px', color: 'white', backgroundImage: 'linear-gradient(rgba(50,50,50,1), rgba(50,50,50,0))', }}>
            <div className="inline-block flex align-items-end justify-content-center p-0 m-0" style={{ width:'5vw' }}>
              <img src={'images/'+disaster.current.disaster+'2.png'} alt={disaster.current.disaster} style={{ borderRadius: '50%', width: '5vw' }} />
            </div>
            <div className="inline-block flex flex-column align-items-center justify-content-center p-0 m-0" style={{ width:'70%' }}>                
                <p className="p-0 m-0 flex" style={{ fontSize: '1vw', fontWeight: 'bold' }} dangerouslySetInnerHTML={{__html: displayDisasterLoss.current}} />
            </div>
          </div>

          <div className="flex flex-column align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-1" style={{ height: 'fit-contents', maxHeight: '80%', width: '80%', borderRadius: '20px', color: 'white', backgroundImage: 'linear-gradient(rgba(50,50,50,0), rgba(50,50,50,1))', }}>
            <p className="p-0 m-0 mb-2" style={{ color: '#fff',textAlign: 'center', fontSize: '1.5vw' }}>{number2word(disaster.current.event_number)} {disaster.current.disaster} events occured:</p>
            <p className="p-0 m-2" style={{ color: '#fff',textAlign: 'left', fontSize: '1vw', lineHeight: '1.6' }} dangerouslySetInnerHTML={{__html: displayDisasterDetails.current}} />
          </div>

          <div className="flex flex-column align-self-center align-items-center justify-content-center p-0 m-0 pt-1 mt-1" style={{ height: '60px', maxHeight: '10%', width: '70%', borderRadius: '20px' }}>
            <p className="p-0 m-0" style={{ color: '#fff', fontSize: '1.5vw', textShadow: '2px 2px 4px #000' }}>Have your choices proven to be effective?</p>
            <Button className="bg-gray-700 hover:bg-green-600 border-gray-800 m-1" label="Let's Find out!" icon="fa-solid fa-book-open-reader" onClick={()=>(processClick('DisasterProfile','DisasterResult'))} rounded raised />    
          </div>

        </div>
        <div className="cell">
          <Button className="hover:bg-gray-700 hover:text-white border-gray-900 m-1" style={{backgroundColor: "#aaa", color: "#333"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('DisasterProfile','DisasterResult'))} />
        </div>
        <div className="cell cell3hmerge">
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Mission" onClick={()=>(processTransition('DisasterProfile','Strategy','Instruction'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Selections" onClick={()=>(processTransition('DisasterProfile','Strategy','Configuration'))} rounded raised />
          <Button className="bg-gray-400 hover:bg-gray-500 border-gray-800 m-1" label="Strategy" onClick={()=>(processClick('DisasterProfile','Timer'))} rounded raised />
        </div>
      
      </div>
    </div>
  );
}
