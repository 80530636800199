import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { intersect,randomIntFromInterval,calculateScore,returnVisuals,number2word } from '../../../App';
import { useState } from 'react';

export const StrategyResults = (props) => {
  const {
    showComponent,
    hideComponent,
    setShowInstruction,
    setShowConfiguration,
    round,
    selectedLevel,
    selectedCountry,
    selectedCrop,
    crops,
    setShowPickCrop,
    setSelectedCrop,
    setShowLevel,
    CountryProfiles,
    CropProfiles,
    benefits,
    setBenefits,
    tokens,
    setTokens,
    points,
    setPoints,
    showCultivatedYield,
    setShowCultivatedYield,
    setShowTimer,
    setShowPreGame,
    setShowStrategy,
    setShowEvaluation,
    policyMakerStrategy,
    setPolicyMakerStrategy,
    communityLeaderStrategy,
    setCommunityLeaderStrategy,
    farmerStrategy,
    setFarmerStrategy,
    showResults,
    setShowResults,
    StrategyCards,
    showCooperationScore,
    setShowCooperationScore
  } = props;

   const processClick = (hide,show)=>{
    hideComponent(hide);
    showComponent(show);
  }
  const processTransition = (hide,hideGroup,show)=>{
    hideComponent(hide);
    hideComponent(hideGroup);
    showComponent(show);
  }

  let policyMakerTokens = "";
  let communityLeaderTokens = "";
  let farmerTokens = "";
  Object.entries(tokens['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerTokens = policyMakerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderTokens = communityLeaderTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });
  Object.entries(tokens['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerTokens = farmerTokens+"<div class='align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: "+returnVisuals(rounds[1]).color+"; color: #fff; border-radius: 50%'><i class='"+returnVisuals(rounds[1]).icon+" m-1 p-0'></i></div>";
    }
  });

  let policyMakerPoints = "";
  let communityLeaderPoints = "";
  let farmerPoints = "";
  Object.entries(points['Policy Maker']).map((rounds)=>{
    if(rounds[1] !== null){
      policyMakerPoints = policyMakerPoints+"<div id='PMPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });
  Object.entries(points['Community Leader']).map((rounds)=>{
    if(rounds[1] !== null){
      communityLeaderPoints = communityLeaderPoints+"<div id='CLPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";
    }
  });
  Object.entries(points['Farmer']).map((rounds)=>{
    if(rounds[1] !== null){
      farmerPoints = farmerPoints+"<div id='FPoints' class='flex align-items-center justify-content-center p-0 m-0' style='height: 25px; width: 25px; background: #7CB9E8; color: #fff; border-radius: 50%; text-align: center; vertical-align: middle;'>"+rounds[1]+"</div>";      
    }
  });

  const [displayScoreDetail,setdisplayScoreDetail] = useState<boolean>(false);

  let policyMakerCoins = policyMakerStrategy.budget-policyMakerStrategy.spend;
  let communityLeaderCoins = communityLeaderStrategy.budget-communityLeaderStrategy.spend;
  let farmerCoins = farmerStrategy.budget-farmerStrategy.spend;

  return (
    <div className="container" style={{ backgroundImage: "url('images/background.png'),url('images/clouds.png')", backgroundSize: "100% 100%" }}>
      <div className="header-row">
        <div>
        <img src="images/fao-logo-en.png" style={{ maxWidth: '250px', paddingTop: 4, paddingLeft: 4}} alt='Food and Agriculture Organization of the United Nations' />
        </div>        
        <div className="cell flex-column">
          <span style={{ fontSize: '3vw', fontWeight: '900', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px', textAlign: 'center' }}>Player's Strategies</span>          
          <p className="p-0 m-0" style={{ fontSize: '1.5vw', fontWeight: '400', textAlign: 'center' }}>Let's discuss about the strategies you picked.</p>
        </div>
        <div className="cell flex-column align align-items-start">
          <div className="flex flex-row p-0 m-0"><Chip label={"Round "+round} icon="fa-solid fa-arrows-rotate" style={{height: '25px'}} />{selectedLevel !== 0? <Chip label={"Level "+selectedLevel} icon="fa-solid fa-user-graduate" style={{height: '25px'}} />:undefined}{selectedCountry !== ''?<Chip label={selectedCountry} icon="pi pi-flag" style={{height: '25px'}} />:undefined}</div>
          <div className="flex flex-row p-0 m-0"><Chip label={'Selected Crops: '+crops.current['display']} icon="fa-solid fa-seedling" style={{height: '25px'}} /><Chip label={'Benefits: +'+benefits.coins} icon="fa-solid fa-coins" style={{height: '25px'}} /></div>
          <div className="flex flex-row p-0 m-0"><Chip label='Policy Maker:' icon="fa-solid fa-user-pen" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: policyMakerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: policyMakerTokens}} /><Chip label={policyMakerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={policyMakerStrategy.budget+'-'+policyMakerStrategy.spend} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Com. Leader:' icon="fa-solid fa-people-group" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: communityLeaderPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: communityLeaderTokens}} /><Chip label={communityLeaderCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={communityLeaderStrategy.budget+'-'+communityLeaderStrategy.spend} style={{height: '25px'}} />}</div>
          <div className="flex flex-row p-0 m-0"><Chip label='Farmer:' icon="fa-solid fa-person-digging" style={{height: '25px'}} />{!displayScoreDetail && <><div className='flex align-items-center justify-content-center' style={{ fontSize: '1rem', fontWeight: 'bolder' }} dangerouslySetInnerHTML={{__html: farmerPoints}} /><div className='flex align-items-center justify-content-center' style={{ fontSize: '1.15rem' }} dangerouslySetInnerHTML={{__html: farmerTokens}} /><Chip label={farmerCoins.toString()} icon="pi pi-calculator" style={{height: '25px'}} onClick={()=>setdisplayScoreDetail(true)} /></>}{displayScoreDetail && <Chip onClick={()=>setdisplayScoreDetail(false)} label={farmerStrategy.budget+'-'+farmerStrategy.spend} style={{height: '25px'}} />}</div>
        </div>
      </div>

      <div className="content">
                    
          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-left" rounded text raised onClick={()=>(processClick('StrategyResults','Timer'))} />
          </div>

          <div className="cell cell2vmerge flex-column">
            <div className="flex flex-row align-items-start justify-content-center" style={{ width: 'fit-content' }}>
              <div className="flex flex-column" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
                <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/policy_maker.png' alt='Policy Maker' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Policy Maker</p>              
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Policy Maker'][policyMakerStrategy[1]].strategy} ({policyMakerStrategy[1]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Policy Maker'][policyMakerStrategy[2]].strategy} ({policyMakerStrategy[2]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Policy Maker'][policyMakerStrategy[3]].strategy} ({policyMakerStrategy[3]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Policy Maker'][policyMakerStrategy[4]].strategy} ({policyMakerStrategy[4]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Policy Maker'][policyMakerStrategy[5]].strategy} ({policyMakerStrategy[5]})</p>              
                <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-5" style={{ maxHeight: '25%', width: '70%', background: '#246046', color: '#FFFCF3', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' style={{ fontSize: '1vw' }} /><p className="p-0 m-0" style={{ fontSize: '1vw' }}>Coins Used: {policyMakerStrategy.spend}<br/>Coins Left: {policyMakerStrategy.budget+benefits.coins-policyMakerStrategy.spend}</p></div>
              </div>
              <div className="flex flex-column" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
                <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/community_leader.png' alt='Community Leader' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Community Leader</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Community Leader'][communityLeaderStrategy[1]].strategy} ({communityLeaderStrategy[1]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Community Leader'][communityLeaderStrategy[2]].strategy} ({communityLeaderStrategy[2]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Community Leader'][communityLeaderStrategy[3]].strategy} ({communityLeaderStrategy[3]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Community Leader'][communityLeaderStrategy[4]].strategy} ({communityLeaderStrategy[4]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Community Leader'][communityLeaderStrategy[5]].strategy} ({communityLeaderStrategy[5]})</p>
                <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-5" style={{ maxHeight: '25%', width: '70%', background: '#246046', color: '#FFFCF3', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' style={{ fontSize: '1vw' }} /><p className="p-0 m-0"  style={{ fontSize: '1vw' }}>Coins Used: {communityLeaderStrategy.spend}<br/>Coins Left: {communityLeaderStrategy.budget+benefits.coins-communityLeaderStrategy.spend}</p></div>
              </div>
              <div className="flex flex-column" style={{width: '25vw', height: '100%', minHeight: '370px', background: '#FFE6A5', borderRadius: '20px', padding: 10, margin: 20 }}>
                <div className="flex align-self-center align-items-center justify-content-center" style={{width: '17vw', background: '#FFFCF3', borderRadius: '20px'}}><img src='images/farmer.png' alt='Farmers' style={{maxWidth: '100%', padding: 5}} /></div>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1.5vw', fontWeight: '900', textAlign: 'center', textShadow: '0px 1px, 1px 0px, 1px 1px', letterSpacing: '1px' }}>Farmers</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Farmer'][farmerStrategy[1]].strategy} ({farmerStrategy[1]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Farmer'][farmerStrategy[2]].strategy} ({farmerStrategy[2]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Farmer'][farmerStrategy[3]].strategy} ({farmerStrategy[3]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Farmer'][farmerStrategy[4]].strategy} ({farmerStrategy[4]})</p>
                <p className="p-0 m-0 mb-1" style={{ fontSize: '1vw', textAlign: 'left' }}>- {StrategyCards.current['Farmer'][farmerStrategy[5]].strategy} ({farmerStrategy[5]})</p>
                <div className="flex flex-row align-self-center align-items-center justify-content-center p-0 m-0 pt-2 pb-2 mt-5" style={{ maxHeight: '25%', width: '70%', background: '#246046', color: '#FFFCF3', borderRadius: '20px' }}><i className='fa fa-coins m-1 p-0' style={{ fontSize: '1vw' }} /><p className="p-0 m-0"  style={{ fontSize: '1vw' }}>Coins Used: {farmerStrategy.spend}<br/>Coins Left: {farmerStrategy.budget+benefits.coins-farmerStrategy.spend}</p></div> 
              </div>
            </div>
          </div>

          <div className="cell cell2vmerge">
            <Button className="hover:bg-green-700 hover:text-white border-green-900 m-1" style={{backgroundColor: "#fff5d9", color: "#38BA8B"}} icon="pi pi-arrow-right" rounded text raised onClick={()=>(processClick('StrategyResults','CooperationScore'))} />
          </div>

          <div className="cell cell3hmerge p-0 m-0" style={{padding: 0, margin: 0}}>
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Mission" onClick={()=>(processTransition('StrategyResults','Strategy','Instruction'))} rounded raised />
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Selections" onClick={()=>(processTransition('StrategyResults','Strategy','Configuration'))} rounded raised />
            <Button className="bg-green-200 hover:bg-green-300 border-green-600 m-1" label="Strategies" onClick={()=>(processClick('StrategyResults','Timer'))} rounded raised />
          </div> 
      
      </div>
    </div>
  );
}
